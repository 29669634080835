import React from "react";
import { Box, Typography, Chip } from "@mui/material";

const ShipperCard = ({ shipper }) => {
  return (
    <Box
      sx={{
        my: 1,
        mx: 2,
        p: 2,
        boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.3)",
        background: "white",
        borderRadius: 2,
        border: "1px solid #E0E0E0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          {shipper.account_name}
        </Typography>
        <div>
          {shipper?.equipment_types?.length > 0 &&
            shipper?.equipment_types.map((item, index) => (
              <Chip
                key={index}
                label={item?.name}
                size="medium"
                sx={{
                  backgroundColor: "#f5f5f5",
                  border: "none",
                  borderRadius: "16px",
                  //height: "26px",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 0.5,
                }}
              />
            ))}
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          color: "text.secondary",
          fontSize: "0.875rem",
        }}
      >
        <Typography variant="body2">
          Phone: {shipper.account_phone ? shipper.account_phone : "N/A"}
        </Typography>
        <Typography variant="body2">•</Typography>
        <Typography variant="body2">
          Email: {shipper.account_email ? shipper.account_email : "N/A"}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: "bold" }} color="success">
          {shipper.origin_city},{shipper.origin_state} to{" "}
          {shipper.destination_city},{shipper.destination_state}
        </Typography>
        <Typography variant="body2">
          Owner: {shipper.owners?.[0]?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default ShipperCard;
