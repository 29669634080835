import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/ReactDraft.scss";
import axios from "axios";
import JoditDemo from "./JoditDemo";

class EditSignatureModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    isLoading: false,
    uploadedImages: [],
    content: "",
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  updateContent = (value) => {
    this.setState({ content: value });
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getSignature();
  }
  getSignature = () => {
    axios({
      method: "GET",
      url: `/users/email-signature`,
    }).then((res) => {
      if (res.data.email_signature !== null) {
        this.setState({ content: res.data.email_signature });
      }
    });
  };
  editSignature = () => {
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/users/email-signature`,
      data: {
        email_signature: this.state.content,
      },
    })
      .then((res) => {
        this.setState({ isLoading: false });
        this.props.getSignature();
        this.props.handleCloseModal();
      })
      .catch((err) => this.setState({ isLoading: false }));
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{
                height: "53vh",
                top: "12%",
                width: "45vw",
                left: this.props.isEmailSettings ? "25%" : "35%",
              }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Signature</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div
                style={{
                  height: "42.2vh",
                  maxHeight: "42.2vh",
                  minHeight: "36vh",
                }}
              >
                <JoditDemo
                  content={this.state.content}
                  updateContent={this.updateContent}
                  accountId={this.props.accountId}
                  carrierId={this.props.carrierId}
                  opportunityId={this.props.opportunityId}
                  contactId={this.props.contactId}
                  isTemplate={true}
                />
              </div>
              <div className="modal__buttons">
                <button
                  type="button"
                  onClick={this.editSignature}
                  className="modal__confirm-button"
                  disabled={this.state.isLoading}
                  style={{
                    padding: "2px 17px",
                    width: "80.22px",
                    height: "35px",
                    right: "8%",
                  }}
                >
                  {this.state.isLoading ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default EditSignatureModal;
