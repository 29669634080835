import { Formik, Form } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import InputField from "../Reusables/InputField/InputField";
import MuiSelect from "../Reusables/Select/MuiSelect";
import { MenuItem } from "@mui/material";
import ReactModal from "react-modal";
import ClearIcon from "@mui/icons-material/Clear";

const ReplyPreference = ({
  replyToAddress,
  signature,
  showModal,
  handleCloseModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    replyTo: Yup.string().required("Reply to is required"),
  });
  const initialValues = {
    name: "",
    replyTo: "",
  };

  const submitQuote = (values, { resetForm, setFieldValue }) => {
    const { name, replyTo } = values;

    setLoading(true);
    axios({
      method: "POST",
      url: `/emails/settings`,
      data: {
        email_from_name: name,
        email_reply_to: replyTo,
        email_signature: signature,
      },
    })
      .then((res) => {
        resetForm();

        NotificationManager.success("Reply Preference Save Successfully!");

        setErrorMessage(false);
        setLoading(false);
        handleCloseModal();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel="onRequestClose Example"
      onRequestClose={handleCloseModal}
      style={modalStyle}
      ariaHideApp={false}
    >
      <div className="muiModal">
        <div className="modal__content" style={{ height: `40vh`, top: `25%` }}>
          <div className="modal__header">
            <div className="modal__head">
              <h2 className="modal__heading">Reply Preference</h2>
            </div>
            <button
              onClick={handleCloseModal}
              className="modal__close"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                top: "24px",
                cursor: "pointer",
              }}
            >
              {" "}
              <ClearIcon fontSize="small" />
            </button>
          </div>
          <div className="modal__form">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={submitQuote}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form>
                  <InputField
                    label="Name"
                    name="name"
                    type="text"
                    sx={{ width: "100%", zIndex: 0, marginBottom: "20px" }}
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name && touched.name}
                    helperText={errors.name}
                  />

                  <MuiSelect
                    name="replyTo"
                    value={values.replyTo}
                    error={errors.replyTo && touched.replyTo}
                    helperText={errors.replyTo}
                    label="Reply To"
                    sx={{ marginBottom: "20px", zIndex: 0 }}
                    onChange={handleChange}
                    placeholder="Reply To"
                    menuItem={replyToAddress?.map((addr) => (
                      <MenuItem key={addr} value={addr}>
                        {addr}
                      </MenuItem>
                    ))}
                  />

                  <div className="modal__buttons">
                    <button
                      type="button"
                      className="modal__cancel-button"
                      onClick={handleCloseModal}
                      style={{ zIndex: "auto" }}
                    >
                      Cancel
                    </button>
                    <button
                      tabIndex="0"
                      className="modal__confirm-button"
                      type="submit"
                      style={{ marginRight: "10px" }}
                    >
                      {loading ? "Saving" : "Save"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ReplyPreference;
