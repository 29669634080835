import React, { Component } from "react";
import "../../sass/Reports.scss";
import "../../sass/DropdownHeader.scss";
import axios from "axios";
import moment from "moment";
import ReportIcon from "../../images/ReportsLogo.svg";
import DeleteIcon from "../../images/DeleteIconRed.svg";
import ColumnReportIcon from "../../images/ReportsColumnIcon.svg";
import ExportButtonIcon from "../../images/ExportButtonIcon.svg";
import ScoreCardIcon from "../../images/scorecard.png";
import CreatableSelect from "react-select/creatable";
import { Col, Row, setConfiguration } from "react-grid-system";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import Pagination from "react-js-pagination";
import NotificationManager from "react-notifications/lib/NotificationManager";
import ExitReportModal from "./ExitReportModal";
import DeleteReportModal from "./DeleteReportModal";
import Select from "react-select";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../images/DragHandler.svg";
import SendEmailIcon from "../../images/AccountEmailIcon.svg";
import SendEmailReportModal from "../SendEmail/SendEmailReportModal";
import PostMarkAppModal from "./PostMarkAppModal";
import PostMarkAppVerificationModal from "./PostMarkAppVerificationModal";
import { usStatesList } from "../../constants/usStatesList";
import { countriesList } from "../../constants/countriesList";
import BillingRedirectModal from "./BillingRedirectModal";
import BillingCreditModal from "./BillingCreditModal";
import AccountsPie from "../Analytics/Pages/AccountsPie";
import AccountsLine from "../Analytics/Pages/AccountsLine";
import AccountsColumn from "../Analytics/Pages/AccountsColumn";
import AccountsScoreCard from "../Analytics/Pages/AccountsScoreCard";
import ReportExitDropdown from "./ReportExitDropdown";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReusableModal from "../Reusables/ReusableModal";
import {
  isValidCurrency,
  getCurrencyValue,
} from "../../utils/Helper/reusableFunctions";
import { CurrencyList } from "../../constants/currencylist";
import { AsYouType } from "libphonenumber-js";
import BulkStatusUpdateModal from "./BulkStatusUpdateModal";
import TransferAccountOwnershipModal from "./TransferAccountOwnershipModal";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
  setReportsFilters,
} from "../../redux";
import InputField from "../Reusables/InputField/InputField";
import { SlidersHorizontal } from "lucide-react";
import { Plus } from "lucide-react";
import Popover from "@mui/material/Popover";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

setConfiguration({ gutterWidth: "0" });

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ marginRight: "10px", cursor: "grab" }}
    />
  </span>
));

const SortableItem = sortableElement(({ column, sortButtonClickHandler }) => (
  <th>
    <Row>
      <Col lg={9} xl={9}>
        <DragHandle />
        {column.name}
      </Col>
      <Col lg={3} xl={3}>
        <button
          type="button"
          style={{
            background: "transparent",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => sortButtonClickHandler(column)}
        >
          <i className="fa fa-long-arrow-up"></i>
          <i className="fa fa-long-arrow-down"></i>
        </button>
      </Col>
    </Row>
  </th>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <thead>{children}</thead>;
});

class ReportsAccountTable extends Component {
  state = {
    isInitializing: true,
    filter: "",
    operator: "",
    filter_rules: [
      { id: uuidv4(), attribute: "", operator: "", value: null, type: null },
    ],
    reportsTable: [],
    showColumn: false,
    displayMenu: false,
    reportsData: {},
    reportType: "",
    intialFilters: [],
    isEditing: false,
    reportName: this.props.reportName,
    reportDescription: "",
    errorMessage: false,
    sortKey: null,
    sortDirection: false,
    displayExitMenu: false,
    customFields: [],
    showEmailModal: false,
    showPostMarkModal: false,
    showRedirectModal: false,
    showVerificationModal: false,
    signatureVerified: false,
    signatureId: null,
    isLoading: false,
    exportLoader: false,
    isApplying: false,
    disqualifiedReason: [],
    access: "everyone",
    customFieldRevenueTypeCheck: [],
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
      name: "",
    },
    columnsArray: [
      {
        name: "Account Owner",
        key: "account_owner",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Account Name",
        key: "name",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Website",
        key: "website",
        selected: true,
        custom: false,
        type: "hyperlink",
        choices: [],
        editable: true,
      },
      {
        name: "LinkedIn",
        key: "linkedin",
        selected: false,
        custom: false,
        type: "hyperlink",
        choices: [],
        editable: true,
      },
      {
        name: "Description",
        key: "description",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Email",
        key: "email",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Phone",
        key: "phone",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Last Activity Date",
        key: "last_activity_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Age",
        key: "age",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Created Date",
        key: "created_at",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Status Change Date",
        key: "status_changed_at",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Status",
        key: "status",
        selected: false,
        custom: false,
        type: "dropdown",
        choices: [],
        editable: true,
      },
      {
        name: "Address",
        key: "address",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "City",
        key: "city",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "State",
        key: "state",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Zip/Postal",
        key: "zip",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Source",
        key: "source",
        selected: false,
        custom: false,
        type: "dropdown",
        choices: [],
        editable: true,
      },
      {
        name: "Last Edit Date",
        key: "last_updated",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Owner Change Date",
        key: "owner_changed_at",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Country",
        key: "country",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Total Shipments",
        key: "total_shipments",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Last Shipment Date",
        key: "last_shipment_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Revenue",
        key: "total_revenue",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Profit",
        key: "total_profit",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
    ],
    columnsData: [],
    revenueFieldsSum: {},
    showDeleteModal: false,
    showExitModal: false,
    activePage: 1,
    total: 0,
    transferAccount: [],
    excludeArray: [],
    excludeEmailArray: [],
    subscriptionStatus: null,
    transferAccountEmail: [],
    allReportTransfer: false,
    showOwnerModal: false,
    ownerErrorMessage: false,
    bulkStatusErrorMessage: false,
    order_by: null,
    emailCredits: 0,
    showCreditsModal: false,
    analyticsMode: "table",
    showBulkDeleteModal: false,
    showBulkStatusModal: false,
    bulkDeleteLoader: false,
    bulkStatusLoader: false,
    anchorEl: null,
    inputSelectedReports: 0,
    currentPage: 1,
    isSelectedReportLoading: false,
    selectAllRecords: false,
    selectPageRecords: false,
    currentPageReportsTransfer: false,
  };
  wrapperRef = React.createRef();
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false });
  };
  handleDeleteOpenModal = () => {
    this.setState({
      showDeleteModal: true,
    });
  };
  handleDeleteCloseModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };
  handleCreditsOpenModal = () => {
    this.setState({
      showCreditsModal: true,
    });
  };
  handleCreditsCloseModal = () => {
    this.setState({
      showCreditsModal: false,
    });
  };
  handleOwnerOpenModal = () => {
    this.setState({
      showOwnerModal: true,
    });
  };
  handleOwnerCloseModal = () => {
    this.setState({
      showOwnerModal: false,
    });
  };
  handleBulkDeleteOpenModal = () => {
    this.setState({
      showBulkDeleteModal: true,
    });
  };
  handleBulkDeleteCloseModal = () => {
    this.setState({
      showBulkDeleteModal: false,
    });
  };
  handleBulkStatusOpenModal = () => {
    this.setState({
      showBulkStatusModal: true,
    });
  };
  handleBulkStatusCloseModal = () => {
    this.setState({
      showBulkStatusModal: false,
    });
  };
  handleRedirectOpenModal = () => {
    this.setState({
      showRedirectModal: true,
    });
  };
  handleRedirectCloseModal = () => {
    this.setState({
      showRedirectModal: false,
    });
  };
  handleExitOpenModal = () => {
    this.setState({
      showExitModal: true,
    });
  };
  handleExitCloseModal = () => {
    this.setState({
      showExitModal: false,
    });
  };
  handleEmailOpenModal = () => {
    this.setState({
      showEmailModal: true,
    });
  };
  handleEmailCloseModal = () => {
    this.setState({
      showEmailModal: false,
    });
  };
  handlePostMarkOpenModal = () => {
    this.setState({
      showPostMarkModal: true,
    });
  };
  handlePostMarkCloseModal = () => {
    this.setState({
      showPostMarkModal: false,
    });
  };
  handleVerificationOpenModal = () => {
    this.setState({
      showVerificationModal: true,
    });
  };
  handleVerificationCloseModal = () => {
    this.setState({
      showVerificationModal: false,
    });
  };
  checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      this.verifySignature(res.data.postmark_signature_verified);
      this.setState({
        signatureVerified: res.data.postmark_signature_verified,
        signatureId: res.data.postmark_signature_id,
        emailCredits: res.data.postmark_email_balance,
      });
    });
  };
  verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          this.handleVerificationCloseModal();
          this.checkUserProfile();
          this.handleCreditsOpenModal();
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      this.setState({
        signatureVerified: res.data.postmark_signature_verified,
        signatureId: res.data.postmark_signature_id,
        emailCredits: res.data.postmark_email_balance,
      });
    });
  };
  emailDecision = () => {
    if (this.state.subscriptionStatus !== "active") {
      this.handleRedirectOpenModal();
    } else if (this.state.signatureId === null) {
      this.handlePostMarkOpenModal();
    } else if (!this.state.signatureVerified) {
      this.handleVerificationOpenModal();
    } else if (
      this.state.emailCredits === null ||
      this.state.transferAccountEmail.length > this.state.emailCredits
    ) {
      this.handleCreditsOpenModal();
    } else if (
      (this.state.emailCredits === null ||
        this.state.transferAccountEmail.length === 0) &&
      this.state.total > this.state.emailCredits
    ) {
      this.handleCreditsOpenModal();
    } else {
      this.handleEmailOpenModal();
    }
  };
  accessChange = () => {
    const checkboxView = document.getElementById("checkboxView");
    if (checkboxView.checked) {
      this.setState({ access: "everyone" });
    } else {
      this.setState({ access: "only_me" });
    }
  };
  filterRulesIsIn = () => {
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules?.map((rule) => {
        const { operator, value } = rule;
        if (operator === "is_in" || operator === "is_not_in") {
          rule.value = value.map((val) => ({ value: val, label: val }));
        }
        return rule;
      }),
    }));
  };
  getReportById = (firstTime = false) => {
    const selectedReport = this.props.reportsFilters?.find(
      (report) => report.id === `${this.props.reportId}ReportsAccountTable`
    );
    const activePage = firstTime ? selectedReport?.filters?.activePage || 1 : 1;
    axios({
      method: "GET",
      url: `/reports/${this.props.reportId}`,
    }).then((res) => {
      const columns = res.data.report.columns.replace("mobile", "phone");
      const columnsPositionArray = columns.split(",");
      if (!firstTime) {
        this.props.setReportsFilters({
          id: `${this.props.reportId}ReportsAccountTable`,
          filters: {
            activePage: 1,
          },
        });
      }
      this.setState(
        {
          reportsData: res.data.report,
          reportType: res.data.report.type,
          reportName: res.data.report.name,
          access: res.data.report.access,
          filter_rules: res.data.report.filters?.map((rule) => {
            const { operator, value } = rule;
            if (operator === "is_in" || operator === "is_not_in") {
              rule.value = value.map((val) => ({ value: val, label: val }));
            }
            return rule;
          }),
          reportDescription: res.data.report.description,
          columnsData: columns,
          columnsToShow: columns.split(","),
          order_by: res.data.report.order_by?.split(","),
          activePage,
          columnsArray: [
            {
              name: "Account Owner",
              key: "account_owner",
              selected: columns.includes("account_owner") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("account_owner")
                ? columnsPositionArray.indexOf("account_owner")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Account Name",
              key: "name",
              selected: columns.includes("name") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("name")
                ? columnsPositionArray.indexOf("name")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  1,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Website",
              key: "website",
              selected: columns.includes("website") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("website")
                ? columnsPositionArray.indexOf("website")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  2,
              type: "hyperlink",
              choices: [],
              editable: true,
            },
            {
              name: "Description",
              key: "description",
              selected: columns.includes("description") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("description")
                ? columnsPositionArray.indexOf("description")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  3,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Email",
              key: "email",
              selected: columns.includes("email") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("email")
                ? columnsPositionArray.indexOf("email")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  4,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Phone",
              key: "phone",
              selected: columns.includes("phone") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("phone")
                ? columnsPositionArray.indexOf("phone")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  5,
              type: "decimal",
              choices: [],
              editable: true,
            },
            {
              name: "Last Activity Date",
              key: "last_activity_date",
              selected: columns.includes("last_activity_date") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("last_activity_date")
                ? columnsPositionArray.indexOf("last_activity_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  6,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Age",
              key: "age",
              selected: columns.includes("age") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("age")
                ? columnsPositionArray.indexOf("age")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  7,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Created Date",
              key: "created_at",
              selected: columns.includes("created_at") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("created_at")
                ? columnsPositionArray.indexOf("created_at")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  8,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Status Change Date",
              key: "status_changed_at",
              selected: columns.includes("status_changed_at") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("status_changed_at")
                ? columnsPositionArray.indexOf("status_changed_at")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  9,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Status",
              key: "status",
              selected: columns.includes("status") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("status")
                ? columnsPositionArray.indexOf("status")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  10,
              type: "dropdown",
              choices: this.props.allAccountStatuses,
              editable: true,
            },
            {
              name: "Address",
              key: "address",
              selected: columns.includes("address") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("address")
                ? columnsPositionArray.indexOf("address")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  11,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "City",
              key: "city",
              selected: columns.includes("city") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("city")
                ? columnsPositionArray.indexOf("city")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  12,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "State",
              key: "state",
              selected: columns.includes("state") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("state")
                ? columnsPositionArray.indexOf("state")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  13,
              type: "dropdown",
              choices: [],
              editable: true,
            },
            {
              name: "Zip/Postal",
              key: "zip",
              selected: columns.includes("zip") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("zip")
                ? columnsPositionArray.indexOf("zip")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  14,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Source",
              key: "source",
              selected: columns.includes("source") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("source")
                ? columnsPositionArray.indexOf("source")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  15,
              type: "dropdown",
              choices: this.props.allAccountSources,
              editable: true,
            },
            {
              name: "LinkedIn",
              key: "linkedin",
              selected: columns.includes("linkedin") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("linkedin")
                ? columnsPositionArray.indexOf("linkedin")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  16,
              type: "hyperlink",
              choices: [],
              editable: true,
            },
            {
              name: "Last Edit Date",
              key: "last_updated",
              selected: columns.includes("last_updated") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("last_updated")
                ? columnsPositionArray.indexOf("last_updated")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  17,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Owner Change Date",
              key: "owner_changed_at",
              selected: columns.includes("owner_changed_at") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("owner_changed_at")
                ? columnsPositionArray.indexOf("owner_changed_at")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  18,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Country",
              key: "country",
              selected: columns.includes("country") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("country")
                ? columnsPositionArray.indexOf("country")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  19,
              type: "dropdown",
              choices: [],
              editable: true,
            },
            {
              name: "Total Shipments",
              key: "total_shipments",
              selected: columns.includes("total_shipments") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("total_shipments")
                ? columnsPositionArray.indexOf("total_shipments")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  20,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Last Shipment Date",
              key: "last_shipment_date",
              selected: columns.includes("last_shipment_date") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("last_shipment_date")
                ? columnsPositionArray.indexOf("last_shipment_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  21,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Revenue",
              key: "total_revenue",
              selected: columns.includes("total_revenue") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("total_revenue")
                ? columnsPositionArray.indexOf("total_revenue")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  22,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Profit",
              key: "total_profit",
              selected: columns.includes("total_profit") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("total_profit")
                ? columnsPositionArray.indexOf("total_profit")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  23,
              type: "text",
              choices: [],
              editable: false,
            },
          ],
        },
        () => this.getReportInitializer(res.data.report.order_by, activePage),
        () => this.filterRulesIsIn()
      );
      this.getCustomFields(columns, columnsPositionArray);
    });
  };
  logCall = (description, selectedAccount) => {
    axios({
      method: "POST",
      url: `/activities/create`,
      data: {
        name: `${description?.direction} Call`,
        description: `${description?.direction} Call made at ${Date(
          description?.startTime
        )} to <span style="color: #4A9876;">${
          description?.to?.phoneNumber
        }</span>`,
        category_id: 1,
        account_id: selectedAccount.id,
        type: "activity_logged",
      },
    }).then((res) => {
      NotificationManager.success("Call logged.");
    });
  };
  handleClickToDial = (e) => {
    const data = e.data;
    if (
      data &&
      data.type === "rc-active-call-notify" &&
      data.call &&
      data.call.terminationType === "final"
    ) {
      const selectedAccount = this.state.reportsTable?.find((table) =>
        data.call.to.phoneNumber.includes(table.formatted_phone)
      );
      if (selectedAccount) {
        this.logCall(data.call, selectedAccount);
      }
    }
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("message", this.handleClickToDial);
    this.checkUserProfile();
    this.checkSubscription();
    axios({
      method: "GET",
      url: `/close-reasons`,
    }).then((response) => {
      this.setState({
        disqualifiedReason: response.data.reasons,
      });
    });
    this.getReportById(true);
  }
  getReportInitializer = (orderby, page = 1) => {
    const orderBy = orderby?.split(",");
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val?.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    this.setState({
      sortKey: orderby === null ? null : orderBy[0],
      sortDirection:
        orderby === null ? null : orderBy[1] === "desc" ? true : false,
    });
    axios({
      method: "POST",
      url: `/${this.state.reportType}/reports?_limit=30&_page=${page}`,
      data: {
        filter_rules: newValue,
        sort_key: orderby === null ? null : orderBy[0],
        sort_dir: orderby === null ? null : orderBy[1],
      },
    })
      .then((res) => {
        this.setState({
          reportsTable: res.data.accounts,
          revenueFieldsSum: res.data.revenue_fields,
          total: res.data.total,
          isInitializing: false,
        });
      })
      .catch((err) => this.setState({ isInitializing: false }));
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("message", this.handleClickToDial);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };
  renderTableData = (
    accountData,
    tableData,
    name,
    value,
    dateFormat,
    newTabData,
    currencySymbol
  ) => {
    let renderItem = <td>{value} </td>;
    if (!tableData.editable) {
      if (tableData.type === "date") {
        renderItem = <td>{value && moment(value).format(dateFormat)}</td>;
      } else if (name === "account_owner") {
        renderItem = (
          <td>
            {value?.length === 0
              ? "Unassigned Leads"
              : value?.map((val) => val?.name)?.join(", ")}
          </td>
        );
      } else if (name === "total_profit" || name === "total_revenue") {
        const currency = this.props.companyOnboarding?.currency;
        const currencySymbol = CurrencyList.find(
          (cur) => cur.code === currency
        );
        renderItem = (
          <td>
            {value
              ? currencySymbol.symbol +
                getCurrencyValue(value)?.toLocaleString("en-US")
              : null}
          </td>
        );
      } else if (name === "total_shipments") {
        renderItem = <td>{value?.toLocaleString("en-US")}</td>;
      } else {
        renderItem = <td>{value}</td>;
      }
    } else if (name === "name") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  this.props.setTab({ ...newTabData, blank: true });
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(newTabData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              {value}
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: accountData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === accountData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(
                  accountData,
                  name,
                  e.target.value,
                  tableData.custom
                )
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (
      name === "mobile" ||
      name === "formatted_mobile" ||
      name === "phone" ||
      name === "formatted_phone"
    ) {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { color: "#4A9876" }
              }
            >
              <a
                target="_blank"
                style={{ color: "#4A9876", cursor: "pointer" }}
                data-stringify-link={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                delay="150"
                data-sk="tooltip_parent"
                href={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                rel="noopener noreferrer"
                tabindex="-1"
                data-remove-tab-index="true"
              >
                {value}
              </a>
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: accountData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === accountData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(
                  accountData,
                  name,
                  e.target.value,
                  tableData.custom
                )
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (
      name === "website" ||
      name === "linkedin" ||
      tableData.type === "hyperlink"
    ) {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
            >
              <a
                href={
                  value
                    ? value.startsWith("https://") ||
                      value.startsWith("http://")
                      ? value
                      : "https://" + value
                    : null
                }
                target="_blank"
                rel="noreferrer"
                style={{
                  cursor: "pointer",
                  color: "#4A9876",
                }}
              >
                {value}
              </a>
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: accountData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === accountData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(
                  accountData,
                  name,
                  e.target.value,
                  tableData.custom
                )
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (
      tableData.type === "revenue" ||
      name === "revenue" ||
      name === "total_profit" ||
      name === "total_revenue"
    ) {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value && currencySymbol}
            {value && getCurrencyValue(value)?.toLocaleString("en-US")}
          </span>
          <input
            type="text"
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldRevenueChangeHandler(
                accountData,
                name,
                e.target.value,
                tableData.custom
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (
      tableData.type === "text" ||
      tableData.type === "paragraph" ||
      tableData.type === "decimal"
    ) {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            type={tableData.type === "decimal" ? "number" : "text"}
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(
                accountData,
                name,
                e.target.value,
                tableData.custom
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "date") {
      const newDate = value ? new Date(value) : new Date();
      const offsetDateCalc =
        newDate === null || newDate === ""
          ? new Date()
          : newDate.getTimezoneOffset() < 0
          ? new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000)
          : new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
      const offsetDate = offsetDateCalc;
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value && moment(value).format(dateFormat)}
          </span>
          <DatePicker
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
            selected={offsetDate}
            autoComplete="off"
            todayButton="Today"
            utcOffset={0}
            value={offsetDate}
            onChange={(date) => {
              this.fieldChangeHandler(
                accountData,
                name,
                date,
                tableData.custom
              );
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              });
            }}
            placeholderText="Date"
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            className="inlineReportEdit-input"
          />
        </td>
      );
    } else if (tableData.type === "dropdown" || tableData.type === "radio") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          {name === "state" ? (
            accountData.country === "United States" ? (
              <select
                className="inlineReportEdit-input"
                value={value}
                onChange={(e) =>
                  this.fieldChangeHandler(
                    accountData,
                    name,
                    e.target.value,
                    tableData.custom
                  )
                }
                onFocus={() =>
                  this.setState({
                    inputFocused: {
                      id: accountData.id,
                      isTrue: true,
                      name: name,
                    },
                  })
                }
                onBlur={() =>
                  this.setState({
                    inputFocused: {
                      id: "",
                      isTrue: false,
                      name: "",
                    },
                  })
                }
              >
                <option value="" selected disabled>
                  -Select-
                </option>
                {usStatesList.map((list) => (
                  <option key={list} value={list}>
                    {list}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="inlineReportEdit-input"
                value={value}
                onChange={(e) =>
                  this.fieldChangeHandler(
                    accountData,
                    name,
                    e.target.value,
                    tableData.custom
                  )
                }
                onFocus={() =>
                  this.setState({
                    inputFocused: {
                      id: accountData.id,
                      isTrue: true,
                      name: name,
                    },
                  })
                }
                onBlur={() =>
                  this.setState({
                    inputFocused: {
                      id: "",
                      isTrue: false,
                      name: "",
                    },
                  })
                }
              />
            )
          ) : (
            <select
              className="inlineReportEdit-input"
              value={value}
              onChange={(e) =>
                this.fieldChangeHandler(
                  accountData,
                  name,
                  e.target.value,
                  tableData.custom
                )
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                })
              }
            >
              <option value="" selected disabled>
                -Select-
              </option>
              {name === "source" || name === "status"
                ? tableData.choices?.map((choice) => (
                    <option key={choice.id} value={choice.name}>
                      {choice.name}
                    </option>
                  ))
                : name === "country"
                ? countriesList?.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))
                : tableData.choices?.map((choice) => (
                    <option key={choice.id} value={choice.value}>
                      {choice.value}
                    </option>
                  ))}
            </select>
          )}
        </td>
      );
    } else if (tableData.type === "checkbox") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value?.toString()}
          </span>
          <input
            type="checkbox"
            className="inlineReportEdit-input"
            value={value}
            checked={value}
            style={{ width: "15px" }}
            onChange={() =>
              this.fieldChangeHandler(
                accountData,
                name,
                (!value).toString(),
                tableData.custom
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "multi_select_dropdown") {
      let options = [];
      if (name === "cf_equipment_type" || name === "cf_equipment_types") {
        options = this.props.allEquipmentTypes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (name === "cf_special_requirements") {
        options = this.props.allSpecialRequirements?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (name === "cf_modes") {
        options = this.props.allModes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else {
        options = tableData.choices?.map((choice) => {
          const { value } = choice;
          return { value, label: value };
        });
      }
      const valueArray = value
        ?.split(";")
        ?.filter((val) => val !== "")
        ?.map((val) => ({ label: val, value: val }));
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value?.toString().startsWith(";")
              ? value.replace(/;/g, ", ").substr(1).slice(0, -2)
              : value}
          </span>
          <Select
            autoFocus={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
            }
            className="inlineReportEdit-input"
            closeMenuOnSelect={false}
            value={valueArray}
            isMulti
            name={name}
            menuPlacement="auto"
            // styles={colourStyles}
            options={options}
            classNamePrefix="select"
            onChange={(selectedOption) =>
              this.customFieldMultiDropdownChangeHandler(
                accountData,
                name,
                selectedOption
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    }

    return renderItem;
  };
  fieldChangeHandler = (account, name, value, isCustom) => {
    let accountData = account;
    let formattedValue = value;
    if (
      name === "mobile" ||
      name === "formatted_mobile" ||
      name === "phone" ||
      name === "formatted_phone"
    ) {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      formattedValue = formatter.input(value); // Format the input value
    }
    accountData = isCustom
      ? {
          ...account,
          custom_fields: {
            ...account.custom_fields,
            [name]: formattedValue === "" ? null : formattedValue,
          },
        }
      : { ...account, [name]: formattedValue === "" ? null : formattedValue };
    this.setState({
      reportsTable: this.state.reportsTable?.map((acc) => {
        if (acc.id === account.id) {
          if (isCustom) {
            acc = {
              ...account,
              custom_fields: {
                ...account.custom_fields,
                [name]: formattedValue === "" ? null : formattedValue,
              },
            };
          } else {
            acc = {
              ...account,
              [name]: formattedValue === "" ? null : formattedValue,
            };
          }
        }
        return acc;
      }),
    });
    this.submitAccountDataHandler(accountData);
  };
  fieldRevenueChangeHandler = (account, name, value, isCustom) => {
    let accountData = account;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      accountData = isCustom
        ? {
            ...account,
            custom_fields: {
              ...account.custom_fields,
              [name]: value === "" ? null : value,
            },
          }
        : { ...account, [name]: value === "" ? null : value };
      this.setState({
        reportsTable: this.state.reportsTable?.map((acc) => {
          if (acc.id === account.id) {
            if (isCustom) {
              acc = {
                ...account,
                custom_fields: {
                  ...account.custom_fields,
                  [name]: value === "" ? null : value,
                },
              };
            } else {
              acc = { ...account, [name]: value === "" ? null : value };
            }
          }
          return acc;
        }),
      });
      this.submitAccountDataHandler(accountData);
    }
  };
  customFieldMultiDropdownChangeHandler = (
    account,
    fieldName,
    selectedOptions
  ) => {
    let accountData = account;
    this.setState((prevState) => ({
      reportsTable: this.state.reportsTable?.map((acc) => {
        if (acc.id === account.id) {
          acc = {
            ...account,
            custom_fields: {
              ...account.custom_fields,
              [fieldName]:
                ";" +
                selectedOptions?.map((option) => option.value)?.join(";") +
                ";",
            },
          };
        }
        return acc;
      }),
    }));
    this.submitAccountDataHandler(accountData);
  };
  submitAccountDataHandler = debounce((accountData) => {
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i;
    if (
      accountData.phone !== null &&
      accountData.phone !== "" &&
      !phoneValidator.test(accountData.phone)
    ) {
      return false;
    }
    if (
      accountData.email !== null &&
      accountData.email !== "" &&
      !emailValidator.test(accountData.email)
    ) {
      return false;
    }
    if (!accountData.name) {
      return false;
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      accountData.custom_fields[i] = getCurrencyValue(
        accountData.custom_fields[i]
      );
    }
    axios({
      method: "PUT",
      url: `/accounts/${accountData.id}`,
      data: {
        name: accountData.name,
        state: accountData.state,
        country: accountData.country,
        zip: accountData.zip,
        city: accountData.city,
        address: accountData.address,
        source: accountData.source,
        email: accountData.email,
        formatted_phone: accountData.formatted_phone,
        phone_ext: accountData.phone_ext,
        website: accountData.website
          ? accountData.website.startsWith("https://") ||
            accountData.website.startsWith("http://")
            ? accountData.website
            : "https://" + accountData.website
          : null,
        linkedin: accountData.linkedin
          ? accountData.linkedin.startsWith("https://") ||
            accountData.linkedin.startsWith("http://")
            ? accountData.linkedin
            : "https://" + accountData.linkedin
          : null,
        description: accountData.description,
        preferred_communication: accountData.preferred_communication?.filter(
          (pref) => pref !== ""
        ),
        custom_fields: accountData.custom_fields,
      },
    })
      .then((response) => {
        // NotificationManager.success("Account edited successfully.");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing account.");
        }
      });
  }, 500);
  getCustomFields = (selectedArray, columnsPositionArray) => {
    const customField = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field, index) => {
            const { label, name, id, type, choices } = field;
            return {
              name: label,
              key: name,
              selected: selectedArray.includes(id) ? true : false,
              custom: true,
              id,
              position: columnsPositionArray.includes(id.toString())
                ? columnsPositionArray.indexOf(id.toString())
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  index,
              type,
              choices,
              editable: true,
            };
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const columnsArray = this.state.columnsArray;
    const customFieldTypeCheck = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState({
      columnsArray: columnsArray.concat(customFieldArray),
      customFields: this.props.allAccountCustomFields,
      customFieldRevenueTypeCheck: objTypeCheck,
    });
  };
  getReport = (page = 1) => {
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val?.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      return rule;
    });
    this.props.setReportsFilters({
      id: `${this.props.reportId}ReportsAccountTable`,
      filters: {
        activePage: page,
      },
    });
    this.setState({ isApplying: true });
    if (checkError === true) {
      axios({
        method: "POST",
        url: `/${this.state.reportType}/reports?_limit=30&_page=${page}`,
        data: {
          filter_rules: newValue,
          sort_key: this.state.sortKey,
          sort_dir: this.state.sortDirection === true ? "desc" : "asc",
        },
      })
        .then((res) => {
          this.setState({
            reportsTable: res.data.accounts,
            revenueFieldsSum: res.data.revenue_fields,
            total: res.data.total,
            isApplying: false,
            selectPageRecords: false, // To Reset Select this Page Filter On New Page
          });
        })
        .catch((err) => this.setState({ isApplying: false }));
    } else {
      this.setState({ isApplying: false });
    }
  };
  changeInfoHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  deleteReport = (id, e) => {
    e.preventDefault();
    axios({
      method: "DELETE",
      url: `/reports/${id}`,
    }).then((res) => this.props.deleteTab(this.props.deleteTabIndex));
  };
  changeHandler = (index, e) => {
    const { name, value } = e.target;
    name === "attribute" && this.changeType(index, e);
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule[name] = value;
          if (
            (rule.operator === "contains" ||
              rule.operator === "not_contains") &&
            Array.isArray(rule.value)
          ) {
            rule.value = "";
          }
          if (
            rule.operator === "is_empty" ||
            rule.operator === "is_not_empty" ||
            rule.operator === "today" ||
            rule.operator === "yesterday" ||
            rule.operator === "this_week" ||
            rule.operator === "last_7_days" ||
            rule.operator === "last_30_days" ||
            rule.operator === "greater_than_14_days" ||
            rule.operator === "greater_than_30_days" ||
            rule.operator === "greater_than_60_days" ||
            rule.operator === "this_month" ||
            rule.operator === "this_quarter" ||
            rule.operator === "next_quarter" ||
            rule.operator === "last_quarter" ||
            rule.operator === "this_year" ||
            rule.operator === "only_me" ||
            rule.operator === "my_team" ||
            rule.operator === "everyone" ||
            rule.operator === "is_unassigned" ||
            rule.operator === "last_year"
          ) {
            rule.value = null;
          }
          if (rule.operator === "custom_period") {
            rule.value = [];
          }
        }
        return rule;
      }),
    }));
  };
  changeType = (index, e) => {
    const { value } = e.target;
    const fieldType = this.state.customFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.find((field) => field.id === parseInt(value))
        );
      })
      .filter((data) => data !== undefined && data !== false);
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule.type = fieldType[0]?.type;
          rule.choices = fieldType[0]?.choices;
        }
        return rule;
      }),
    }));
  };
  valueChangeHandler = (index, valueIndex, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          if (rule["value"] === null) {
            rule["value"] = [];
          }
          rule["value"][valueIndex] = value;
        }
        return rule;
      }),
    }));
  };
  isInChangeHandler = (index, selected) => {
    console.log("selected:", selected);
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    console.log("QWE:", selectedArray);

    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule["value"] = selected;
        }
        return rule;
      }),
    }));
  };
  addFilter = () => {
    this.setState((prevState) => ({
      filter_rules: [
        ...prevState.filter_rules,
        { id: uuidv4(), attribute: "", operator: "", value: [] },
      ],
    }));
  };
  deleteFilter = (index) => {
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.filter((rule, i) => i !== index),
    }));
  };
  transferOwnership = (primaryOwnerId, sharedAccountOwners) => {
    if (primaryOwnerId === null) {
      this.setState({ ownerErrorMessage: true });
      return false;
    }
    const newPrimaryOwnerId =
      primaryOwnerId === "unassigned" ? null : primaryOwnerId;
    if (this.state.allReportTransfer === true) {
      axios({
        method: "PUT",
        url: `/admin/change-owner`,
        data: {
          report_id: this.props.reportId,
          excluded_ids: this.state.excludeArray?.map((id) => parseInt(id)),
          owners: !newPrimaryOwnerId
            ? []
            : sharedAccountOwners
                ?.map((user) => ({
                  is_primary: false,
                  owner_id: user.value,
                }))
                .concat([
                  {
                    is_primary: true,
                    owner_id: newPrimaryOwnerId,
                  },
                ]),
        },
      })
        .then((res) => {
          this.getReport();
          this.handleOwnerCloseModal();
          this.setState({
            transferAccount: [],
            transferAccountEmail: [],
            allReportTransfer: false,
            excludeArray: [],
            excludeEmailArray: [],
          });
          NotificationManager.success("Ownership transferred successfully.");
        })
        .catch((err) =>
          NotificationManager.error("Error transferring ownership")
        );
    } else {
      axios({
        method: "PUT",
        url: `/admin/change-owner`,
        data: {
          account_ids: this.state.transferAccount,
          owners: !newPrimaryOwnerId
            ? []
            : sharedAccountOwners
                ?.map((user) => ({
                  is_primary: false,
                  owner_id: user.value,
                }))
                .concat([
                  {
                    is_primary: true,
                    owner_id: newPrimaryOwnerId,
                  },
                ]),
        },
      })
        .then((res) => {
          this.getReport();
          this.handleOwnerCloseModal();
          this.setState({
            transferAccount: [],
            transferAccountEmail: [],
            allReportTransfer: false,
            excludeArray: [],
            excludeEmailArray: [],
          });
          NotificationManager.success("Ownership transferred successfully.");
        })
        .catch((err) =>
          NotificationManager.error("Error transferring ownership")
        );
    }
  };
  bulkStatusUpdate = (status) => {
    this.setState({ bulkStatusLoader: true });
    if (status === null) {
      this.setState({ bulkStatusErrorMessage: true, bulkStatusLoader: false });
      return false;
    }
    axios({
      method: "PUT",
      url: `/admin/bulk-status-change`,
      data: {
        ...(this.state.allReportTransfer && { report_id: this.props.reportId }),
        ...(this.state.allReportTransfer && {
          excluded_ids: this.state.excludeArray?.map((id) => parseInt(id)),
        }),
        ...(!this.state.allReportTransfer && {
          account_ids: this.state.transferAccount,
        }),
        ...(!this.state.currentPageReportsTransfer && {
          account_ids: this.state.transferAccount,
        }),
        status,
      },
    })
      .then((res) => {
        this.getReport();
        this.handleBulkStatusCloseModal();
        this.setState({
          transferAccount: [],
          transferAccountEmail: [],
          allReportTransfer: false,
          excludeArray: [],
          excludeEmailArray: [],
          bulkStatusErrorMessage: false,
          bulkStatusLoader: false,
          currentPageReportsTransfer: false,
        });
        NotificationManager.success("Status updated successfully.");
      })
      .catch((err) => {
        this.setState({ bulkStatusLoader: false });
        NotificationManager.error("Error updating status.");
      });
  };
  bulkDelete = () => {
    if (this.state.allReportTransfer === true) {
      this.setState({
        bulkDeleteLoader: true,
      });
      axios({
        method: "PUT",
        url: `/admin/bulk-delete`,
        data: {
          report_id: this.props.reportId,
          excluded_ids: this.state.excludeArray?.map((id) => parseInt(id)),
        },
      })
        .then((res) => {
          this.getReport();
          this.handleBulkDeleteCloseModal();
          this.setState({
            transferAccount: [],
            transferAccountEmail: [],
            excludeArray: [],
            excludeEmailArray: [],
            allReportTransfer: false,
            bulkDeleteLoader: false,
          });
          NotificationManager.success("Records deleted successfully.");
        })
        .catch((err) => {
          NotificationManager.error("Error deleting records");
          this.setState({
            bulkDeleteLoader: false,
          });
        });
    } else {
      this.setState({
        bulkDeleteLoader: true,
      });
      axios({
        method: "PUT",
        url: `/admin/bulk-delete`,
        data: {
          account_ids: this.state.transferAccount,
        },
      })
        .then((res) => {
          this.getReport();
          this.handleBulkDeleteCloseModal();
          this.setState({
            transferAccount: [],
            transferAccountEmail: [],
            excludeArray: [],
            excludeEmailArray: [],
            allReportTransfer: false,
            bulkDeleteLoader: false,
          });
          NotificationManager.success("Records deleted successfully.");
        })
        .catch((err) => {
          NotificationManager.error("Error deleting records");
          this.setState({
            bulkDeleteLoader: false,
          });
        });
    }
  };
  allReportTransfer = () => {
    this.setState({
      allReportTransfer: !this.state.allReportTransfer,
      transferAccount: [],
      transferAccountEmail: [],
      excludeArray: [],
      excludeEmailArray: [],
    });
  };

  applySelectedReports = () => {
    if (this.state.selectAllRecords && !this.state.allReportTransfer) {
      this.allReportTransfer();
    } else if (this.state.allReportTransfer && !this.state.selectAllRecords) {
      this.allReportTransfer();
    }
    if (this.state.selectPageRecords) {
      this.currentPageReportTransfer();
    } else if (
      !this.state.selectPageRecords &&
      !this.state.selectAllRecords &&
      !this.state.inputSelectedReports
    ) {
      this.currentPageReportTransfer();
    }
    if (this.state.inputSelectedReports > 0) {
      if (this.state.inputSelectedReports <= 30) {
        this.setState({
          transferAccount: this.state?.reportsTable
            .slice(0, this.state?.inputSelectedReports)
            ?.map((item) => item.id.toString()),
          transferAccountEmail: this.state.reportsTable
            ?.slice(0, this.state.inputSelectedReports)
            ?.map((item) => ({
              email: item.email,
            })),
        });
      } else {
        this.getReportByInput();
      }
    }
    this.handleClose();
  };

  getReportByInput = () => {
    this.setState({ isSelectedReportLoading: true });

    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val?.value);
      return {
        attribute,
        operator,
        value,
      };
    });

    axios({
      method: "POST",
      url: `/${this.state.reportType}/reports?_limit=${this.state.inputSelectedReports}&_page=1`,
      data: {
        filter_rules: newValue,
      },
    })
      .then((res) => {
        this.setState({
          transferAccount: res.data.accounts.map((item) => item.id.toString()),
          transferAccountEmail: res.data.accounts.map((item) => ({
            email: item.email,
          })),
          isSelectedReportLoading: false,
        });
      })
      .catch((err) => this.setState({ isApplying: false }));
  };

  currentPageReportTransfer = () => {
    if (this.state.selectPageRecords) {
      this.setState({
        currentPageReportsTransfer: !this.state.currentPageReportsTransfer,
        transferAccount: this.state.reportsTable.map((item) =>
          item.id.toString()
        ),
        transferAccountEmail: this.state.reportsTable.map((item) => ({
          email: item.email,
        })),
      });
    } else {
      this.setState({
        currentPageReportsTransfer: !this.state.currentPageReportsTransfer,
        transferAccount: [],
        transferAccountEmail: [],
      });
    }
  };
  checkSubscription = () => {
    axios({
      method: "GET",
      url: `/company/${this.props.authUser.company_id}/subscription-status`,
    }).then((res) => {
      this.setState({
        subscriptionStatus: res.data.subscription.status,
      });
    });
  };
  transferAccountChangeHandler = (report, e) => {
    const { value } = e.target;
    if (e.target.name === "transferAccount") {
      if (e.target.checked === true) {
        this.setState((prevState) => ({
          transferAccount: [
            ...this.state.transferAccount.filter((com) => com !== ""),
            value,
          ],
          transferAccountEmail: [
            ...this.state.transferAccountEmail.filter((com) => com !== ""),
            { email: report.email, account_id: report.id },
          ],
        }));
      } else {
        this.setState({
          transferAccount: this.state.transferAccount.filter(
            (pc) => pc !== value
          ),
          transferAccountEmail: this.state.transferAccountEmail.filter(
            (pc) => pc.email !== report.email
          ),
        });
      }
    }
  };
  excludeAccountChangeHandler = (email, e) => {
    const { value } = e.target;
    if (e.target.checked === false) {
      this.setState((prevState) => ({
        excludeArray: [
          ...this.state.excludeArray.filter((com) => com !== ""),
          value,
        ],
        excludeEmailArray: [
          ...this.state.excludeEmailArray.filter((com) => com !== ""),
          email,
        ],
      }));
    } else {
      this.setState({
        excludeArray: this.state.excludeArray.filter((pc) => pc !== value),
        excludeEmailArray: this.state.excludeEmailArray.filter(
          (pc) => pc !== email
        ),
      });
    }
  };
  storeSorting = () => {
    this.getReport();
    let sortDirection = "asc";
    this.state.sortDirection === true
      ? (sortDirection = "desc")
      : (sortDirection = "asc");
    axios({
      method: "PATCH",
      url: `/reports/${this.props.reportId}`,
      data: {
        order_by: `${this.state.sortKey},${sortDirection}`,
      },
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      return rule;
    });
    this.setState({ isLoading: true });
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val?.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    let sortDirection = "asc";
    this.state.sortDirection === true
      ? (sortDirection = "desc")
      : (sortDirection = "asc");
    let order_by =
      this.state.sortKey === null
        ? null
        : `${this.state.sortKey},${sortDirection}`;
    if (checkError === true) {
      axios({
        method: "PUT",
        url: `/reports/${this.props.reportId}`,
        data: {
          name: this.state.reportName,
          type: this.state.reportType,
          description: this.state.reportDescription,
          access: this.state.access,
          filters: newValue,
          columns: this.state.columnsArray
            .filter((columns) => columns.selected === true)
            .map((columns) => (columns.custom ? columns.id : columns.key)),
          order_by,
        },
      })
        .then((res) => {
          this.getReportById();
          this.setState({ isLoading: false, isEditing: false });
          NotificationManager.success("Report edited successfully.");
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          NotificationManager.error("Error editing report.");
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  downloadReport = () => {
    this.setState({ exportLoader: true });
    axios({
      method: "POST",
      url: `/${this.state.reportType}/export`,
      data: {
        report_id: this.props.reportId,
      },
    })
      .then((data) => {
        this.setState({ exportLoader: false });
        console.log("KKOO", data);
        let a = document.createElement("a");
        a.href = data.data.file_url;
        // a.download = "employees.json";
        a.click();
      })
      .catch((err) => this.setState({ exportLoader: false }));
  };

  sortButtonClickHandler = (column) => {
    this.setState(
      {
        sortKey: column.custom ? column.id : column.key,
        sortDirection: !this.state.sortDirection,
      },
      this.getReport
    );
  };
  getCarrierFieldsOptions = (id) => {
    const carrierFields = this.state.columnsArray?.filter(
      (arr) =>
        arr.key === "cf_special_requirements" ||
        arr.key === "cf_equipment_type" ||
        arr.key === "cf_equipment_types" ||
        arr.key === "cf_modes"
    );
    const fieldName = carrierFields.find(
      (arr) => arr?.id?.toString() === id?.toString()
    )?.key;
    console.log("getCarrierFieldsOptions", id, fieldName);
    let options = [];
    if (
      fieldName === "cf_equipment_type" ||
      fieldName === "cf_equipment_types"
    ) {
      options = this.props.allEquipmentTypes?.map((type) => {
        return { value: type.name, label: type.name };
      });
    } else if (fieldName === "cf_special_requirements") {
      options = this.props.allSpecialRequirements?.map((type) => {
        return { value: type.name, label: type.name };
      });
    } else if (fieldName === "cf_modes") {
      options = this.props.allModes?.map((type) => {
        return { value: type.name, label: type.name };
      });
    }
    return options;
  };

  render() {
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    const carrierFields = this.state.columnsArray?.filter(
      (arr) =>
        arr.key === "cf_special_requirements" ||
        arr.key === "cf_equipment_type" ||
        arr.key === "cf_equipment_types" ||
        arr.key === "cf_modes"
    );
    const { columnsArray } = this.state;
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        minWidth: "200px",
        outline: "#C0EAD8",
        minHeight: "30px",
        padding: "5px auto",
        transform: "translateY(-10px)",
        marginRight: "12px",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? "#C0EAD8"
            : null,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : "#C0EAD8"),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "#C0EAD8",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "black",
        fontSize: "13px",
      }),
    };

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <div
        className="reports"
        style={
          this.state.isEditing === true ? { padding: "0px", margin: "0px" } : {}
        }
      >
        <ReactTooltip />
        {this.state.isEditing === true ? (
          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="reports-inputFields">
              <InputField
                id="reportName"
                label="Report Name"
                size="small"
                type="text"
                name="reportName"
                placeholder="Enter Report Name"
                className="reports-inputFields"
                value={this.state.reportName}
                onChange={this.changeInfoHandler}
                disabled={false}
              />
              <div style={{ marginTop: "10px" }}>
                <InputField
                  id="reportDescription"
                  label="Report Description"
                  size="small"
                  type="text"
                  name="reportDescription"
                  placeholder="Enter Report Description"
                  className="reports-inputFields"
                  value={this.state.reportDescription}
                  onChange={this.changeInfoHandler}
                  disabled={false}
                  multiline={true}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>
                <span
                  className="general-heading"
                  style={
                    this.state.access === "only_me"
                      ? {
                          color: "#3AAB7B",
                          fontSize: "20px",
                        }
                      : {
                          color: "#A7ABAA",
                          fontSize: "20px",
                        }
                  }
                >
                  <i
                    className="fa fa-lock"
                    aria-hidden="true"
                    style={{ transform: "translateY(3px)" }}
                  ></i>{" "}
                  &nbsp;
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    value={this.state.access}
                    id="checkboxView"
                    checked={this.state.access === "everyone"}
                    onChange={this.accessChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span
                  className="general-heading"
                  style={
                    this.state.access === "everyone"
                      ? {
                          color: "#3AAB7B",
                          fontSize: "20px",
                          transform: "translateY(2px)",
                        }
                      : {
                          color: "#A7ABAA",
                          fontSize: "20px",
                          transform: "translateY(2px)",
                        }
                  }
                >
                  {" "}
                  &nbsp;<i className="fa fa-users" aria-hidden="true"></i>
                </span>
              </div>
              <button
                className="button-md"
                type="button"
                onClick={this.submitHandler}
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? "Saving ..." : "Save Report"}
              </button>
              <div>
                {this.state.isEditing === true && (
                  <ReportExitDropdown
                    handleDeleteOpenModal={this.handleDeleteOpenModal}
                    handleExitOpenModal={this.handleExitOpenModal}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{ borderBottom: "1px solid #E3E3E3", marginBottom: "10px" }}
          >
            <div
              className="customerInfo-icon-report"
              style={{ transform: "translateY(-16px)" }}
            >
              <img
                src={ReportIcon}
                alt=""
                width="17px"
                style={{ transform: "translateY(9px)" }}
              />
            </div>
            <div className="customerInfo-info">
              <h2 className="c-type">Report</h2>
              <h1 className="c-name">
                {this.state.reportName}{" "}
                <span style={{ color: "#4A9876", marginLeft: "15px" }}>
                  {this.state.total} records
                </span>
              </h1>
            </div>
            {this.state.isEditing === false && (
              <div
                style={{
                  marginTop: "2vh",
                  float: "right",
                  marginRight: "30px",
                }}
              >
                <button
                  type="button"
                  className="button-md-black-outline reports-edit-button"
                  onClick={() => this.setState({ isEditing: true })}
                >
                  Edit Report
                </button>
              </div>
            )}
          </div>
        )}
        <DeleteReportModal
          showOpportunityModal={this.state.showDeleteModal}
          setShowOpportunityModal={this.handleDeleteOpenModal}
          handleCloseModal={this.handleDeleteCloseModal}
          deleteReport={this.deleteReport}
          reportId={this.props.reportId}
          deleteTab={this.props.deleteTab}
          deleteTabIndex={this.props.deleteTabIndex}
        />
        <ExitReportModal
          showOpportunityModal={this.state.showExitModal}
          setShowOpportunityModal={this.handleExitOpenModal}
          handleCloseModal={this.handleExitCloseModal}
        />

        {this.state.isEditing === true && (
          <form>
            <div className="reports-filter">
              <div>
                <h4 style={{ marginTop: "0px" }}>Filters</h4>
              </div>
              {this.state.filter_rules.map((rule, index) => (
                <div style={{ display: "flex" }} key={rule.id}>
                  <div>
                    <label>Filter</label>
                    <select
                      name="attribute"
                      value={rule.attribute}
                      onChange={(e) => this.changeHandler(index, e)}
                      disabled={rule.attribute === "batch"}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <optgroup label="Default Fields">
                        <option value="owner">Owner</option>
                        <option value="account_name">Account Name</option>
                        <option value="created_date">Created Date</option>
                        <option value="status_changed_at">
                          Status Change Date
                        </option>
                        <option value="status">Status</option>
                        <option value="phone">Phone</option>
                        <option value="city">City</option>
                        <option value="state">State</option>
                        <option value="zip">Zip</option>
                        <option value="source">Lead Source</option>
                        <option value="batch" disabled>
                          Batch
                        </option>
                        <option value="disqualified_reason">
                          Disqualified Reason
                        </option>
                        <option value="owner_changed_at">
                          Owner Change Date
                        </option>
                        <option value="last_edit_date">Last Edit Date</option>
                        <option value="age">Age</option>
                        <option value="country">Country</option>
                        <option value="last_activity_date">
                          Last Activity Date
                        </option>
                        {this.props.companyOnboarding?.tms_integrated && (
                          <option value="total_shipments">
                            Total Shipments
                          </option>
                        )}
                        {this.props.companyOnboarding?.tms_integrated && (
                          <option value="last_shipment_date">
                            Last Shipment Date
                          </option>
                        )}
                        {this.props.companyOnboarding?.tms_integrated && (
                          <option value="total_revenue">Revenue</option>
                        )}
                        {this.props.companyOnboarding?.tms_integrated && (
                          <option value="total_profit">Profit</option>
                        )}
                      </optgroup>
                      {this.state.customFields.map((group) => {
                        const { label, fields, meta } = group;
                        return (
                          (meta === null || meta.visible_in_form === true) && (
                            <optgroup label={label}>
                              {fields?.map((field) => {
                                const { id, label } = field;
                                return (
                                  <option key={id} value={id}>
                                    {label}
                                  </option>
                                );
                              })}
                            </optgroup>
                          )
                        );
                      })}
                    </select>
                    {this.state.errorMessage === true &&
                      rule.attribute === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Filters is required field
                        </div>
                      )}
                  </div>
                  {rule.attribute === "last_edit_date" ||
                  rule.attribute === "owner_changed_at" ||
                  rule.type === "date" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="greater_than_equal">
                          equal or greater than
                        </option>
                        <option value="less_than_equal">
                          equal or less than
                        </option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_week">This Week</option>
                        <option value="last_7_days">Last 7 days</option>
                        <option value="last_30_days">Last 30 days</option>
                        <option value="this_month">This Month</option>
                        <option value="this_quarter">This Quarter</option>
                        <option value="next_quarter">Next Quarter</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last Year</option>
                        <option value="custom_period">Custom Period</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "batch" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option value="is" disabled>
                          is
                        </option>
                      </select>
                    </div>
                  ) : rule.attribute === "last_activity_date" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="greater_than_equal">
                          equal or greater than
                        </option>
                        <option value="less_than_equal">
                          equal or less than
                        </option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_week">This Week</option>
                        <option value="last_7_days">Last 7 days</option>
                        <option value="last_30_days">Last 30 days</option>
                        <option value="greater_than_14_days">
                          Greater than last 14 days
                        </option>
                        <option value="greater_than_30_days">
                          Greater than last 30 days
                        </option>
                        <option value="greater_than_60_days">
                          Greater than last 60 days
                        </option>
                        <option value="this_month">This Month</option>
                        <option value="this_quarter">This Quarter</option>
                        <option value="next_quarter">Next Quarter</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last Year</option>
                        <option value="custom_period">Custom Period</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.type === "checkbox" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is_not_empty">is true</option>
                        <option value="is_empty">is false</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.type === "decimal" || rule.type === "number" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="greater_than_equal">
                          equal or greater than
                        </option>
                        <option value="less_than_equal">
                          equal or less than
                        </option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "created_date" ||
                    rule.attribute === "last_shipment_date" ||
                    rule.attribute === "status_changed_at" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="greater_than_equal">
                          equal or greater than
                        </option>
                        <option value="less_than_equal">
                          equal or less than
                        </option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_week">This Week</option>
                        <option value="last_7_days">Last 7 days</option>
                        <option value="last_30_days">Last 30 days</option>
                        <option value="this_month">This Month</option>
                        <option value="this_quarter">This Quarter</option>
                        <option value="next_quarter">Next Quarter</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last Year</option>
                        <option value="custom_period">Custom Period</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "status" ||
                    rule.attribute === "source" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "age" ||
                    rule.attribute === "total_revenue" ||
                    rule.attribute === "total_profit" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "owner" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        onChange={(e) => this.changeHandler(index, e)}
                        value={rule.operator}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="only_me">My Records</option>
                        <option value="my_team">My Team's Records</option>
                        <option value="everyone">Everyone’s Records</option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                        <option value="is_unassigned">
                          is Unassigned Leads
                        </option>
                        <option value="contains">contains</option>
                        <option value="not_contains">does not contain</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "account_name" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                        <option value="contains">contains</option>
                        <option value="not_contains">does not contain</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                        <option value="contains">contains</option>
                        <option value="not_contains">does not contain</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  )}
                  {rule.operator === "custom_period" ? (
                    <div style={{ display: "flex" }}>
                      <div>
                        <label>Start Date</label>
                        <input
                          type="date"
                          name="value"
                          onChange={(e) => this.valueChangeHandler(index, 0, e)}
                          value={rule.value[0]}
                        />
                      </div>
                      <div>
                        <label>End Date</label>
                        <input
                          type="date"
                          name="value"
                          onChange={(e) => this.valueChangeHandler(index, 1, e)}
                          value={rule.value[1]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <label>&nbsp;</label>
                      {rule.operator === "is_empty" ||
                      rule.operator === "is_not_empty" ||
                      rule.operator === "today" ||
                      rule.operator === "yesterday" ||
                      rule.operator === "this_week" ||
                      rule.operator === "last_7_days" ||
                      rule.operator === "last_30_days" ||
                      rule.operator === "greater_than_14_days" ||
                      rule.operator === "greater_than_30_days" ||
                      rule.operator === "greater_than_60_days" ||
                      rule.operator === "this_month" ||
                      rule.operator === "this_quarter" ||
                      rule.operator === "next_quarter" ||
                      rule.operator === "last_quarter" ||
                      rule.operator === "this_year" ||
                      rule.operator === "only_me" ||
                      rule.operator === "my_team" ||
                      rule.operator === "everyone" ||
                      rule.operator === "is_unassigned" ||
                      rule.operator === "last_year" ? (
                        <input
                          type="text"
                          name="value"
                          value={(rule.value = null)}
                          disabled={true}
                          onChange={(e) => this.changeHandler(index, e)}
                        />
                      ) : rule.attribute === "age" ? (
                        <input
                          type="number"
                          name="value"
                          value={rule.value}
                          onChange={(e) => this.changeHandler(index, e)}
                        />
                      ) : rule.attribute === "created_date" ||
                        rule.attribute === "status_changed_at" ||
                        rule.attribute === "last_shipment_date" ||
                        rule.attribute === "last_edit_date" ||
                        rule.attribute === "owner_changed_at" ||
                        rule.attribute === "last_activity_date" ||
                        rule.type === "date" ? (
                        <input
                          type="date"
                          name="value"
                          value={rule.value}
                          onChange={(e) => this.changeHandler(index, e)}
                        />
                      ) : carrierFields
                          ?.map((arr) => arr?.id?.toString())
                          .includes(rule.attribute) ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={this.getCarrierFieldsOptions(rule.attribute)}
                          menuPlacement="auto"
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : rule.operator === "is_in" ||
                        rule.operator === "is_not_in" ? (
                        rule.type === "dropdown" ||
                        rule.type === "multi_select_dropdown" ||
                        rule.type === "radio" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={rule?.choices?.map((choice) => {
                              const { value } = choice;
                              return { value, label: value };
                            })}
                            menuPlacement="auto"
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "owner" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={[
                              // {
                              //   label: "Unassigned Leads",
                              //   value: "unassigned",
                              // },
                            ].concat(
                              this.props.allUsers?.map((user) => {
                                const { name } = user;
                                return { value: name, label: name };
                              })
                            )}
                            styles={colourStyles}
                            placeholder="Add Value"
                            menuPlacement="auto"
                          />
                        ) : rule.attribute === "disqualified_reason" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            menuPlacement="auto"
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.state?.disqualifiedReason
                              ?.filter((reason) => reason.type === "lost")
                              ?.map((reason) => {
                                const { name } = reason;
                                return { value: name, label: name };
                              })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "status" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            menuPlacement="auto"
                            options={this.props.allAccountStatuses?.map(
                              (status) => {
                                return {
                                  label: status.name,
                                  value: status.name,
                                };
                              }
                            )}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "state" ? (
                          <CreatableSelect
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            menuPlacement="auto"
                            options={usStatesList?.map((state) => {
                              return {
                                label: state,
                                value: state,
                              };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                            formatCreateLabel={(inputText) =>
                              `Add "${inputText}"`
                            }
                          />
                        ) : rule.attribute === "source" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            menuPlacement="auto"
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.props.allAccountSources?.map(
                              (status) => {
                                return {
                                  label: status.name,
                                  value: status.name,
                                };
                              }
                            )}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : (
                          <CreatableSelect
                            isMulti
                            menuPlacement="auto"
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={rule.choices?.map((choice) => {
                              return {
                                label: choice.value,
                                value: choice.value,
                              };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                            formatCreateLabel={(inputText) =>
                              `Add "${inputText}"`
                            }
                          />
                        )
                      ) : (
                        <input
                          type="text"
                          name="value"
                          value={rule.value}
                          onChange={(e) => this.changeHandler(index, e)}
                          disabled={rule.attribute === "batch"}
                        />
                      )}
                      {this.state.errorMessage === true &&
                        ((rule.operator === "is_in" &&
                          (rule.value === null ||
                            rule.value === "" ||
                            rule.value.length === 0)) ||
                          (rule.operator === "is_not_in" &&
                            (rule.value === null ||
                              rule.value === "" ||
                              rule.value.length === 0)) ||
                          (rule.operator === "contains" &&
                            (rule.value === null || rule.value === "")) ||
                          (rule.operator === "not_contains" &&
                            (rule.value === null || rule.value === "")) ||
                          (rule.operator === "greater_than" &&
                            (rule.value === null || rule.value === "")) ||
                          (rule.operator === "less_than" &&
                            (rule.value === null || rule.value === ""))) && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Value cannot be empty
                          </div>
                        )}
                    </div>
                  )}
                  {rule.attribute !== "batch" && (
                    <button
                      type="button"
                      onClick={() => this.deleteFilter(index)}
                      style={{
                        background: "transparent",
                        border: "none",
                        borderRadius: "28px",
                        outline: "none",
                        cursor: "pointer",
                        transform: "translateY(12px)",
                      }}
                    >
                      <img src={DeleteIcon} alt="Delete" width="16px" />
                    </button>
                  )}
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  type="button"
                  onClick={this.addFilter}
                  className="button-md-secondary"
                  style={{
                    border: "1px solid #EAEEF3",
                    background: "white",
                  }}
                >
                  Add Filter
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
                <button
                  type="button"
                  className="button-md-secondary"
                  style={{
                    border: "1px solid #EAEEF3",
                    background: "white",
                  }}
                  onClick={() => this.getReport()}
                >
                  {this.state.isApplying ? "Applying ..." : "Apply Filter"}
                </button>
              </div>
            </div>
          </form>
        )}
        <div>
          {this.state.isEditing === true &&
            this.state.analyticsMode === "table" && (
              <div
                className="dropdownTask"
                ref={this.wrapperRef}
                style={{
                  background: "transparent",
                  width: "90px",
                  height: "39px",
                  margin: "20px",
                  marginBottom: "0",
                }}
              >
                <div
                  className="button"
                  style={{
                    fontSize: "14px",
                    color: "black",
                    width: "90px",
                    right: "0",
                    top: "0",
                  }}
                  onClick={this.showDropdownMenu}
                >
                  <div
                    style={{
                      color: "#212123",
                      borderRadius: "4px",
                      border: "1px solid #E7ECF2",
                      padding: "10px",
                      width: "90px",
                    }}
                    className="reports-column-button"
                  >
                    Columns &nbsp;
                    <SlidersHorizontal size={14} />
                  </div>
                </div>

                {this.state.displayMenu ? (
                  <ul
                    style={{
                      width: "260px",
                      background: "white",
                      paddingLeft: "15px",
                      height: "350px",
                      overflowY: "auto",
                      left: "0px",
                      top: "52px",
                    }}
                  >
                    <h4
                      style={{
                        color: "#586874",
                        fontSize: "14px",
                        marginLeft: "0px",
                        textAlign: "center",
                        marginBottom: "10px",
                        borderBottom: "1px solid #586874",
                        paddingBottom: "10px",
                        width: "220px",
                      }}
                    >
                      Displayed Columns
                    </h4>
                    {columnsArray
                      .filter((column) =>
                        !this.props.companyOnboarding?.tms_integrated
                          ? column.key !== "total_shipments" &&
                            column.key !== "last_shipment_date" &&
                            column.key !== "total_revenue" &&
                            column.key !== "total_profit"
                          : this.props.companyOnboarding?.tms_integrated ===
                            "tai"
                          ? column.key !== "total_revenue" &&
                            column.key !== "total_profit"
                          : column
                      )
                      .map((column, index) => {
                        let { name, selected } = column;
                        return (
                          <li style={{ width: "200px", fontSize: "12px" }}>
                            <input
                              type="checkbox"
                              checked={selected}
                              id={column.key + index}
                              onChange={() =>
                                this.setState((prevState) => ({
                                  columnsArray: prevState.columnsArray.map(
                                    (clm) => {
                                      if (clm.key === column.key) {
                                        clm.selected = !clm.selected;
                                      }
                                      return clm;
                                    }
                                  ),
                                }))
                              }
                              style={{
                                height: "15px",
                                display: "inline-block",
                                width: "auto",
                                marginRight: "5px",
                              }}
                            />
                            <label
                              htmlFor={column.key + index}
                              style={{
                                display: "inline-block",
                                cursor: "pointer",
                              }}
                            >
                              {name}
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                ) : null}
              </div>
            )}
          <div className="reports-buttons-container">
            {this.state.isEditing === false && (
              <button
                type="button"
                onClick={this.handleOwnerOpenModal}
                disabled={
                  this.state.total === 0 ||
                  (this.state.allReportTransfer === false &&
                    this.state.transferAccount.length === 0)
                }
                className="button-md-black-outline"
                style={{
                  marginRight: "10px",
                }}
              >
                Transfer Accounts
              </button>
            )}
            {this.props.authUser.permissions.includes(
              "crm:bulk:records:delete"
            ) &&
              this.state.isEditing === false && (
                <button
                  type="button"
                  onClick={this.handleBulkDeleteOpenModal}
                  disabled={
                    this.state.total === 0 ||
                    (this.state.allReportTransfer === false &&
                      this.state.transferAccount.length === 0)
                  }
                  className="button-md-black-outline"
                  style={{ marginRight: "10px" }}
                >
                  Bulk Delete
                </button>
              )}
            {this.state.isEditing === false && (
              <button
                type="button"
                onClick={this.handleBulkStatusOpenModal}
                disabled={
                  this.state.total === 0 ||
                  (this.state.allReportTransfer === false &&
                    this.state.transferAccount.length === 0)
                }
                className="button-md-black-outline"
                style={{ marginRight: "10px" }}
              >
                Bulk Status Update
              </button>
            )}
            {this.state.isEditing === false && (
              <button
                type="button"
                onClick={this.emailDecision}
                className="button-md-black-outline"
                disabled={this.state.total === 0}
              >
                <img src={SendEmailIcon} alt="" style={{ width: "16px" }} />
                &nbsp;&nbsp; Bulk Email
              </button>
            )}
            {this.props.authUser.permissions.includes("crm:exporter") &&
              this.state.isEditing === false && (
                <button
                  type="button"
                  onClick={this.downloadReport}
                  className="button-md"
                  disabled={this.state.exportLoader}
                  style={{ marginLeft: "10px" }}
                >
                  <img src={ExportButtonIcon} alt="" />
                  &nbsp;&nbsp;{" "}
                  {this.state.exportLoader ? "Exporting" : "Export"}
                </button>
              )}
          </div>
        </div>
        <TransferAccountOwnershipModal
          showOpportunityModal={this.state.showOwnerModal}
          setShowOpportunityModal={this.handleOwnerOpenModal}
          handleCloseModal={this.handleOwnerCloseModal}
          transferOwnership={this.transferOwnership}
          ownerErrorMessage={this.state.ownerErrorMessage}
          fromAccountsReport={true}
        />
        <BulkStatusUpdateModal
          showOpportunityModal={this.state.showBulkStatusModal}
          setShowOpportunityModal={this.handleBulkStatusOpenModal}
          handleCloseModal={this.handleBulkStatusCloseModal}
          bulkStatusUpdate={this.bulkStatusUpdate}
          allStatuses={this.props.allAccountStatuses}
          bulkStatusErrorMessage={this.state.bulkStatusErrorMessage}
          fromAccountsReport={true}
          bulkStatusLoader={this.state.bulkStatusLoader}
        />
        <ReusableModal
          showModal={this.state.showBulkDeleteModal}
          handleCloseModal={this.handleBulkDeleteCloseModal}
          submitHandler={this.bulkDelete}
          modalHeading={"Bulk Delete"}
          modalPara={`Are you sure you want to delete these ${
            this.state.allReportTransfer === true
              ? this.state.total
              : this.state.transferAccount.length
          } selected records?`}
          modalHeight={`30`}
          modalTop={`25`}
          loading={this.state.bulkDeleteLoader}
        />
        <SendEmailReportModal
          showOpportunityModal={this.state.showEmailModal}
          setShowOpportunityModal={this.handleEmailOpenModal}
          handleCloseModal={this.handleEmailCloseModal}
          reportName={this.state.reportName}
          totalRecords={this.state.total}
          reportId={this.props.reportId}
          reportType="accounts"
          senderEmail={this.state.transferAccountEmail}
          excludeEmail={this.state.excludeEmailArray}
          source="account"
          fromEmail={this.props.authUser.email}
        />
        <BillingCreditModal
          showOpportunityModal={this.state.showCreditsModal}
          setShowOpportunityModal={this.handleCreditsOpenModal}
          handleCloseModal={this.handleCreditsCloseModal}
          handleEmailOpenModal={this.handleEmailOpenModal}
          checkUserProfile={this.checkUserProfile}
        />
        <BillingRedirectModal
          showOpportunityModal={this.state.showRedirectModal}
          setShowOpportunityModal={this.handleRedirectOpenModal}
          handleCloseModal={this.handleRedirectCloseModal}
        />
        <PostMarkAppModal
          showOpportunityModal={this.state.showPostMarkModal}
          setShowOpportunityModal={this.handlePostMarkOpenModal}
          handleCloseModal={this.handlePostMarkCloseModal}
          handleVerificationOpenModal={this.handleVerificationOpenModal}
          checkUserProfile={this.checkUserProfile}
        />
        <PostMarkAppVerificationModal
          showOpportunityModal={this.state.showVerificationModal}
          setShowOpportunityModal={this.handleVerificationOpenModal}
          handleCloseModal={this.handleVerificationCloseModal}
          checkUserProfile={this.checkUserProfile}
          signatureVerified={this.state.signatureVerified}
          handleEmailOpenModal={this.handleEmailOpenModal}
          checkVerification={this.checkVerification}
        />
        <div
          className="reports-tab-container"
          style={this.state.isEditing === true ? { marginLeft: "20px" } : {}}
        >
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "table",
              })
            }
            data-tip="Tabular view"
          >
            <i className="fa fa-table" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "column",
              })
            }
            data-tip="Column chart"
          >
            <i className="fa fa-bar-chart" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "line",
              })
            }
            data-tip="Line chart"
          >
            <i className="fa fa-line-chart" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "bar",
              })
            }
            data-tip="Bar chart"
          >
            <i
              className="fa fa-bar-chart-o"
              aria-hidden="true"
              style={{ transform: "rotate(90deg)" }}
            ></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "pie",
              })
            }
            data-tip="Pie chart"
          >
            <i className="fa fa-pie-chart" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "scorecard",
              })
            }
            data-tip="Scorecard"
          >
            <img
              src={ScoreCardIcon}
              alt="ScoreCard"
              width="20px"
              height="15px"
            />
          </button>
        </div>
        {this.state.analyticsMode === "table" && (
          <div
            style={{
              width: this.state.isEditing === true ? "98vw" : "93vw",
              overflowX: "auto",
              height: "auto",
              minHeight: "55vh",
            }}
          >
            {this.state.isInitializing === true ? (
              <div class="load-wrapp">
                <div class="load-3">
                  <div class="line"></div>
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
            ) : this.state.isInitializing === false &&
              this.state.reportsTable?.length === 0 ? (
              <div
                style={{
                  margin: "30px auto",
                  marginLeft: "35vw",
                  fontWeight: "500",
                }}
              >
                No records found.
              </div>
            ) : this.state.isEditing === true ? (
              <table
                className="accountList__table"
                style={{
                  width: "98vw",
                  height: "auto",
                  overflowX: "auto",
                  marginLeft: "20px",
                  marginTop: "0",
                }}
              >
                <SortableContainer
                  useDragHandle
                  axis={"x"}
                  onSortEnd={({ oldIndex, newIndex }) => {
                    this.setState(({ columnsArray }) => ({
                      columnsArray: arrayMove(columnsArray, oldIndex, newIndex),
                    }));
                  }}
                >
                  {columnsArray
                    .filter((column) =>
                      !this.props.companyOnboarding?.tms_integrated
                        ? column.key !== "total_shipments" &&
                          column.key !== "last_shipment_date" &&
                          column.key !== "total_revenue" &&
                          column.key !== "total_profit"
                        : this.props.companyOnboarding?.tms_integrated === "tai"
                        ? column.key !== "total_revenue" &&
                          column.key !== "total_profit"
                        : column
                    )
                    .map(
                      (column, index) =>
                        column.selected === true && (
                          <SortableItem
                            key={column.key}
                            column={column}
                            index={index}
                            sortButtonClickHandler={this.sortButtonClickHandler}
                          />
                        )
                    )}
                </SortableContainer>
                <tbody>
                  {this.state?.reportsTable.map((report) => {
                    const { id } = report;
                    return (
                      <tr key={id}>
                        {columnsArray
                          .filter((column) =>
                            !this.props.companyOnboarding?.tms_integrated
                              ? column.key !== "total_shipments" &&
                                column.key !== "last_shipment_date" &&
                                column.key !== "total_revenue" &&
                                column.key !== "total_profit"
                              : this.props.companyOnboarding?.tms_integrated ===
                                "tai"
                              ? column.key !== "total_revenue" &&
                                column.key !== "total_profit"
                              : column
                          )
                          .map(
                            (column) =>
                              column.selected === true &&
                              (column.key === "description" ? (
                                <td>
                                  {report.description?.length > 140
                                    ? report.description?.substring(0, 140)
                                    : report.description}
                                </td>
                              ) : column.key === "revenue" ? (
                                <td>
                                  {report.revenue && currencySymbol.symbol}
                                  {report.revenue &&
                                    getCurrencyValue(
                                      report.revenue
                                    )?.toLocaleString("en-US")}
                                </td>
                              ) : column.key === "total_revenue" ? (
                                <td>
                                  {report.total_revenue
                                    ? currencySymbol.symbol +
                                      getCurrencyValue(
                                        report.total_revenue
                                      )?.toLocaleString("en-US")
                                    : null}
                                </td>
                              ) : column.key === "total_profit" ? (
                                <td>
                                  {report.total_profit
                                    ? currencySymbol.symbol +
                                      getCurrencyValue(
                                        report.total_profit
                                      )?.toLocaleString("en-US")
                                    : null}
                                </td>
                              ) : column.key === "total_shipments" ? (
                                <td>
                                  {report.total_shipments !== 0 &&
                                    report.total_shipments?.toLocaleString(
                                      "en-US"
                                    )}
                                </td>
                              ) : column.key === "mobile" ||
                                column.key === "phone" ? (
                                <td>{report.formatted_phone}</td>
                              ) : moment(
                                  report[column.key] ||
                                    report.custom_fields[column.key],
                                  "YYYY-MM-DDTHH:mm:ss.SSSZ",
                                  true
                                ).isValid() ? (
                                <td>
                                  {(report[column.key] ||
                                    report.custom_fields[column.key]) &&
                                    moment(
                                      report[column.key] ||
                                        report.custom_fields[column.key]
                                    ).format(dateFormat)}
                                </td>
                              ) : column.key === "status" ? (
                                <td style={{ textTransform: "capitalize" }}>
                                  {report.status}
                                </td>
                              ) : column.key === "account_owner" ? (
                                <td>
                                  {report.owners?.length === 0
                                    ? "Unassigned Leads"
                                    : report.owners
                                        ?.map((owner) => owner?.name)
                                        ?.join(", ")}
                                </td>
                              ) : (
                                <td>
                                  {report.custom_fields[column.key] !==
                                    undefined &&
                                  report.custom_fields[column.key] !== null &&
                                  report.custom_fields[column.key]
                                    .toString()
                                    .startsWith(";")
                                    ? report.custom_fields[column.key]
                                        .replace(/;/g, ", ")
                                        .substr(1)
                                        .slice(0, -2)
                                    : report[column.key] ||
                                      report.custom_fields[column.key]}
                                </td>
                              ))
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : this.state.isApplying ? (
              <div class="load-wrapp">
                <div class="load-3">
                  <div class="line"></div>
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
            ) : (
              <table
                className="accountList__tableReport"
                style={{ marginTop: "20px" }}
              >
                <thead>
                  <th style={{ width: "8px" }}>
                    <div className="selectReports">
                      <input
                        type="checkbox"
                        style={{ width: "12px", height: "12px" }}
                        checked={this.state.allReportTransfer ? true : false}
                        onClick={this.handleClick}
                      />
                      <div style={{ position: "relative", left: "-10px" }}>
                        <ArrowDropDownIcon
                          fontSize="small"
                          sx={{ cursor: "pointer" }}
                          onClick={this.handleClick}
                        />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={this.handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ marginTop: "10px" }}
                        >
                          <div className="selectReports__popoverCustom">
                            <div
                              className="numRecInput"
                              style={{ display: "flex" }}
                            >
                              <InputField
                                type="number"
                                placeholder="Select number of records"
                                max={100}
                                value={this.state.inputSelectedReports || ""}
                                error={this.state.inputSelectedReports > 100}
                                helperText="Records limit is 100"
                                onChange={(page) =>
                                  this.setState({
                                    inputSelectedReports: Number(
                                      page.target.value
                                    ),
                                    selectAllRecords: false,
                                    selectPageRecords: false,
                                  })
                                }
                              />
                            </div>

                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <input
                                type="radio"
                                id="selectAllRadio"
                                style={{ width: "12px", height: "12px" }}
                                onChange={() =>
                                  this.setState({
                                    inputSelectedReports: "",
                                    selectPageRecords: false,
                                  })
                                }
                                checked={this.state.selectAllRecords}
                                onClick={() =>
                                  this.setState({
                                    selectAllRecords:
                                      !this.state.selectAllRecords,
                                  })
                                }
                              />
                              <label
                                htmlFor="selectAllRadio"
                                style={{
                                  marginLeft: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <small>
                                  Select all ({this.state.total} records)
                                </small>
                              </label>
                            </div>

                            <div
                              style={{ display: "flex", marginBottom: "10px" }}
                            >
                              <input
                                type="radio"
                                id="selectCurrentRadio"
                                style={{ width: "12px", height: "12px" }}
                                checked={
                                  this.state.selectPageRecords &&
                                  this.state.currentPage ===
                                    this.state.activePage
                                }
                                onChange={() =>
                                  this.setState({
                                    inputSelectedReports: "",
                                    selectAllRecords: false,
                                    currentPage: this.state.activePage,
                                  })
                                }
                                onClick={() =>
                                  this.setState({
                                    selectPageRecords:
                                      !this.state.selectPageRecords,
                                  })
                                }
                              />
                              <label
                                htmlFor="selectCurrentRadio"
                                style={{ marginLeft: "5px" }}
                              >
                                <small>
                                  Select current page (
                                  {this.state?.reportsTable?.length || 0}{" "}
                                  records)
                                </small>
                              </label>
                            </div>

                            <div className="applyBtn">
                              <button
                                type="button"
                                className="button-sm-secondary"
                                onClick={this.handleClose}
                                style={{ marginRight: "5px", fontSize: "12px" }}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="button-sm"
                                onClick={this.applySelectedReports}
                                style={{ marginLeft: "5px", fontSize: "12px" }}
                                disabled={this.state.inputSelectedReports > 100}
                              >
                                {this.state.isSelectedReportLoading
                                  ? "Applying..."
                                  : "Apply"}
                              </button>
                            </div>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </th>
                  {columnsArray
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .map(
                      (column) =>
                        this.state?.columnsData.includes(
                          column.id === undefined ? column.key : column.id
                        ) && (
                          <th key={column.key}>
                            <Row>
                              <Col lg={9} xl={9}>
                                {column.name}
                              </Col>
                              <Col lg={3} xl={3}>
                                <button
                                  type="button"
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    outline: "none",
                                    color: "#657885",
                                  }}
                                  onClick={() =>
                                    this.setState(
                                      {
                                        sortKey: column.custom
                                          ? column.id
                                          : column.key,
                                        sortDirection:
                                          !this.state.sortDirection,
                                      },
                                      this.storeSorting
                                    )
                                  }
                                >
                                  <i className="fa fa-long-arrow-up"></i>
                                  <i className="fa fa-long-arrow-down"></i>
                                </button>
                              </Col>
                            </Row>
                          </th>
                        )
                    )}
                </thead>
                <tbody>
                  {this.state?.reportsTable.map((report) => {
                    const { id, name } = report;
                    let newTabData = {
                      type: "account",
                      id: id,
                      name: name,
                    };
                    return (
                      <tr key={id}>
                        <td style={{ width: "8px" }}>
                          {this.state.allReportTransfer ? (
                            <input
                              type="checkbox"
                              style={{ width: "12px", height: "12px" }}
                              name="transferAccount"
                              value={id}
                              checked={
                                !this.state.excludeArray.includes(id.toString())
                              }
                              onClick={(e) =>
                                this.excludeAccountChangeHandler(
                                  report.email,
                                  e
                                )
                              }
                            />
                          ) : (
                            <input
                              type="checkbox"
                              style={{ width: "12px", height: "12px" }}
                              name="transferAccount"
                              checked={this.state.transferAccount.includes(
                                id.toString()
                              )}
                              value={id}
                              onClick={(e) =>
                                this.transferAccountChangeHandler(report, e)
                              }
                            />
                          )}
                        </td>
                        {columnsArray
                          .filter((column) =>
                            !this.props.companyOnboarding?.tms_integrated
                              ? column.key !== "total_shipments" &&
                                column.key !== "last_shipment_date" &&
                                column.key !== "total_revenue" &&
                                column.key !== "total_profit"
                              : this.props.companyOnboarding?.tms_integrated ===
                                "tai"
                              ? column.key !== "total_revenue" &&
                                column.key !== "total_profit"
                              : column
                          )
                          .map(
                            (column) =>
                              this.state.columnsData.includes(
                                column.id === undefined ? column.key : column.id
                              ) &&
                              this.renderTableData(
                                report,
                                column,
                                column.key === "mobile" ||
                                  column.key === "phone"
                                  ? "formatted_phone"
                                  : column.key,
                                report[
                                  column.key === "mobile" ||
                                  column.key === "phone"
                                    ? "formatted_phone"
                                    : column.key === "account_owner"
                                    ? "owners"
                                    : column.key
                                ] || report.custom_fields[column.key],
                                dateFormat,
                                newTabData,
                                currencySymbol.symbol
                              )
                          )}
                      </tr>
                    );
                  })}
                  <tr style={{ background: "#dbf3e8" }}>
                    <td style={{ width: "8px", padding: "5px 10px" }}></td>
                    {columnsArray
                      .filter((column) =>
                        !this.props.companyOnboarding?.tms_integrated
                          ? column.key !== "total_shipments" &&
                            column.key !== "last_shipment_date" &&
                            column.key !== "total_revenue" &&
                            column.key !== "total_profit"
                          : this.props.companyOnboarding?.tms_integrated ===
                            "tai"
                          ? column.key !== "total_revenue" &&
                            column.key !== "total_profit"
                          : column
                      )
                      .map(
                        (column) =>
                          this.state.columnsData
                            .split(",")
                            .includes(
                              column.id === undefined
                                ? column.key.toString()
                                : column.id.toString()
                            ) && (
                            <td style={{ padding: "5px 10px" }}>
                              {column.key in this.state.revenueFieldsSum ? (
                                <>
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      display: "block",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    Grand Total
                                  </span>
                                  <span>
                                    {currencySymbol.symbol}
                                    {this.state.revenueFieldsSum[
                                      column.key
                                    ]?.toLocaleString("en-US")}
                                  </span>
                                </>
                              ) : null}
                            </td>
                          )
                      )}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
        {this.state.analyticsMode === "table" && (
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={30}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              this.setState({ activePage: page });
              this.getReport(page);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        )}
        {this.state.analyticsMode === "pie" && (
          <AccountsPie
            filter_rules={this.state.filter_rules}
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "line" && (
          <AccountsLine
            filter_rules={this.state.filter_rules}
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "column" && (
          <AccountsColumn
            filter_rules={this.state.filter_rules}
            chartType="column"
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "bar" && (
          <AccountsColumn
            filter_rules={this.state.filter_rules}
            chartType="bar"
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "scorecard" && (
          <AccountsScoreCard
            filter_rules={this.state.filter_rules}
            fromReport={true}
          />
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
    reportsFilters: state.reportsFilters.reports,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
    allAccountCustomFields: state.allAccountCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    setReportsFilters: (reportsFilters) =>
      dispatch(setReportsFilters(reportsFilters)),
  };
};

export default connect(MSP, MDP)(ReportsAccountTable);
