import React, { useCallback } from "react";

import axios from "axios";
import ReactModal from "react-modal";
import { NotificationManager } from "react-notifications";
import { muiModalStyle } from "../../../constants/muiModalStyle";

import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { Box, Checkbox, FormControlLabel, Modal } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../Reusables/InputField/InputField";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { customStylesErr } from "../../../constants/selectStyles";
import GoogleAutocompleteComponent from "../../GoogleComponents/GoogleAutocompleteComponent";
import moment from "moment";
import MuiButton from "../../Reusables/Button/MuiButton";
import AddIcon from "@mui/icons-material/Add";
import { Plus } from "lucide-react";
import CreateCarrierModal from "../../Carrier/CreateCarrierModal";
import CreateCarrierCustomModal from "../../Carrier/CreateCarrierCustomModal";

const AddAvailableTruck = ({
  showModal,
  handleCloseModal,
  allEquipmentTypes,
  companyOnboarding,
  setQuery,
  carrierData,
  isCarrierLoading,
  isCarrierFieldsModified,
  fetchCapacities,
  colourStyles,
}) => {
  const [loading, setLoading] = useState(false);
  const [showNewCarrierModal, setShowNewCarrierModal] = useState(false);
  const [showCarrierModal, setShowCarrierModal] = useState(false);

  // Validation Schema
  const TruckFormSchema = Yup.object().shape({
    carrierName: Yup.object().nullable().required("Carrier name is required"),
    mcNumber: Yup.string(),
    location: Yup.object()
      .shape({
        address: Yup.string().nullable().required("Location is required"),
        lat: Yup.number().nullable(),
        long: Yup.number().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable(),
        country: Yup.string().nullable(),
      })
      .nullable()
      .required("Location is required"),
    equipmentTypes: Yup.string()
      .nullable()
      .required("Equipment type is required"),
    availableDate: Yup.date().required("Available date is required"),
    availableTime: Yup.string()
      .nullable()
      .required("Available time is required"),
    isEmpty: Yup.boolean(),
  });

  // Initial Values
  const truckInitialValues = {
    carrierName: null,
    mcNumber: "",
    location: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    equipmentTypes: null,
    availableDate: new Date(),
    availableTime: null,
    isEmpty: true,
  };

  const submitForm = (values, { resetForm, setFieldValue }) => {
    const { carrierName, availableDate, location, equipmentTypes, isEmpty } =
      values;
    const utcDate = moment(availableDate).utc().format("YYYY-MM-DD HH:mm:ss");

    setLoading(true);
    axios({
      method: "POST",
      url: `/carriers/capacities`,
      data: {
        carrier_id: carrierName.value,
        available_at: utcDate,
        status: isEmpty ? "Empty" : "Loaded",
        notes: "Capacity",
        city: location.city,
        state: location.state,
        zip: location.zip,
        address: location.address,
        lat: location.lat,
        long: location.long,
        is_empty: isEmpty,
        equipment_type_id: equipmentTypes.value,
      },
    })
      .then((res) => {
        resetForm();
        setFieldValue("location", {
          address: null,
          lat: null,
          long: null,
          city: null,
          state: null,
          zip: null,
          country: null,
        });
        fetchCapacities();
        handleCloseModal();
        setLoading(false);
        NotificationManager.success("Available Capacity Saved Successfully!");
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error adding capacity.");
      });
  };

  const handleOpenCarrierModal = () => {
    setShowCarrierModal(true);
  };
  const handleCloseCarrierModal = () => {
    setShowCarrierModal(false);
  };
  const handleOpenNewCarrierModal = () => {
    setShowNewCarrierModal(true);
  };
  const handleCloseNewCarrierModal = () => {
    setShowNewCarrierModal(false);
  };

  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      style={muiModalStyle}
      aria-describedby="modal"
    >
      <div className="muiModal">
        <div className="modal__content" style={{ height: "75vh", top: "15%" }}>
          <div className="modal__header">
            <div className="modal__head">
              <h2 className="modal__heading">Add Capacity</h2>
            </div>
            <button
              onClick={handleCloseModal}
              className="modal__close"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <ClearIcon fontSize="small" />
            </button>
          </div>
          <Formik
            initialValues={truckInitialValues}
            validationSchema={TruckFormSchema}
            onSubmit={submitForm}
          >
            {({ errors, touched, setFieldValue, values, handleChange }) => (
              <Form
                className="modal__form"
                style={{
                  overflowY: "scroll",
                  height: "59vh",
                  maxHeight: "60vh",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <div className="controlsForms" style={{ width: "100%" }}>
                    <Select
                      value={values.carrierName}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      name="carrierName"
                      styles={
                        errors.carrierName && touched.carrierName
                          ? customStylesErr
                          : colourStyles
                      }
                      options={carrierData.map((item) => ({
                        value: item.id,
                        label: item.name,
                        mc: item.mc,
                      }))}
                      placeholder="Select Carrier"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selectedValues) => {
                        setFieldValue("carrierName", selectedValues);
                        setFieldValue("mcNumber", {
                          value: selectedValues.mc,
                          label: selectedValues.mc,
                        });
                      }}
                      noOptionsMessage={({ inputValue }) => {
                        if (inputValue) {
                          setQuery(inputValue);
                        }
                        return isCarrierLoading ? (
                          "Loading..."
                        ) : (
                          <>
                            <div style={{ marginBottom: "5px" }}>
                              <small>No Carrier Found</small>
                            </div>
                            <button
                              className="button-sm"
                              type="button"
                              onClick={
                                isCarrierFieldsModified
                                  ? handleOpenNewCarrierModal
                                  : handleOpenCarrierModal
                              }
                              style={{ float: "" }}
                            >
                              Add Carrier
                              <Plus
                                size={14}
                                color="#62CA9D"
                                strokeWidth={3}
                                style={{
                                  transform: "translateY(2px)",
                                  marginLeft: "5px",
                                }}
                              />
                            </button>
                          </>
                        );
                      }}
                    />
                    {touched.carrierName && errors.carrierName && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: "12px",
                          marginTop: "-10px",
                          marginLeft: "14px",
                        }}
                      >
                        {errors.carrierName}
                      </span>
                    )}
                    <label
                      className="activeLabel"
                      style={{
                        fontSize: "12px",
                        height: "10px",
                        color:
                          errors.carrierName && touched.carrierName
                            ? "#d32f2f"
                            : "",
                      }}
                    >
                      Carrier Name
                    </label>
                  </div>
                  <div className="controlsForms" style={{ width: "100%" }}>
                    <Select
                      value={values.mcNumber}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      name="mcNumber"
                      styles={
                        errors.mcNumber && touched.mcNumber
                          ? customStylesErr
                          : colourStyles
                      }
                      options={carrierData
                        .filter((item) => item.mc)
                        .map((item) => ({
                          value: item.mc,
                          label: item.name,
                          id: item.id,
                        }))}
                      placeholder="-Select-"
                      className="basic-multi-select"
                      type="number"
                      classNamePrefix="select"
                      onChange={(selectedValues) => {
                        setFieldValue("carrierName", {
                          value: selectedValues.id,
                          label: selectedValues.label,
                        });
                        setFieldValue("mcNumber", {
                          value: selectedValues.value,
                          label: selectedValues.value,
                        });
                      }}
                      noOptionsMessage={({ inputValue }) => {
                        if (inputValue) {
                          setQuery(inputValue);
                        }
                        return isCarrierLoading ? (
                          "Loading..."
                        ) : (
                          <>
                            <div style={{ marginBottom: "5px" }}>
                              <small>No Carrier Found</small>
                            </div>
                            <button
                              className="button-sm"
                              type="button"
                              onClick={
                                isCarrierFieldsModified
                                  ? handleOpenNewCarrierModal
                                  : handleOpenCarrierModal
                              }
                              style={{ float: "" }}
                            >
                              Add Carrier
                              <Plus
                                size={14}
                                color="#62CA9D"
                                strokeWidth={3}
                                style={{
                                  transform: "translateY(2px)",
                                  marginLeft: "5px",
                                }}
                              />
                            </button>
                          </>
                        );
                      }}
                    />
                    {touched.mcNumber && errors.mcNumber && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: "12px",
                          marginTop: "-10px",
                          marginLeft: "14px",
                        }}
                      >
                        {errors.mcNumber}
                      </span>
                    )}
                    <label
                      className="activeLabel"
                      style={{
                        fontSize: "12px",
                        height: "10px",
                        color:
                          errors.mcNumber && touched.mcNumber ? "#d32f2f" : "",
                      }}
                    >
                      MC#
                    </label>
                  </div>

                  <div className="controlsForms" style={{ width: "105%" }}>
                    <div>
                      <GoogleAutocompleteComponent
                        name="location"
                        className={
                          touched.location && errors.location
                            ? "formField-err"
                            : "formField"
                        }
                        valueProps={values?.destination?.address}
                        onPlaceSelect={(place) =>
                          setFieldValue("location", place)
                        }
                      />
                    </div>
                    <label
                      className="activeLabel"
                      style={{
                        color:
                          touched.location && errors.location ? "#d32f2f" : "",
                        top: "-12px",
                        fontSize: "12px",
                      }}
                    >
                      Location
                    </label>
                    {touched.location && errors.location && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: "12px",
                        }}
                      >
                        {typeof errors.location === "object" &&
                        errors.location?.address
                          ? errors.location.address
                          : "Location is required"}
                      </span>
                    )}
                  </div>

                  <div className="controlsForms" style={{ width: "100%" }}>
                    <Select
                      name="equipmentTypes"
                      value={values.equipmentTypes}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      styles={
                        errors.equipmentTypes && touched.equipmentTypes
                          ? customStylesErr
                          : colourStyles
                      }
                      options={allEquipmentTypes?.map((type) => {
                        return { value: type.id, label: type.name };
                      })}
                      placeholder="-Select-"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selectedOptions) =>
                        setFieldValue("equipmentTypes", selectedOptions)
                      }
                    />
                    <label
                      className="activeLabel"
                      style={{
                        color:
                          errors.equipmentTypes && touched.equipmentTypes
                            ? "#d32f2f"
                            : "",

                        fontSize: "12px",
                        height: "10px",
                      }}
                    >
                      Equipment Types
                    </label>
                    {errors.equipmentTypes && touched.equipmentTypes && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: "12px",
                        }}
                      >
                        {errors.equipmentTypes}
                      </span>
                    )}
                  </div>

                  <div
                    className="controlsForms"
                    style={{
                      width: "103%",
                    }}
                  >
                    <DatePicker
                      selected={values.availableDate}
                      name="availableDate"
                      value={values.availableDate}
                      onChange={(date) => {
                        setFieldValue("availableDate", date);
                        setTimeout(() => {
                          const timeInput = document.querySelector(
                            'input[name="timePicker"]'
                          );
                          if (timeInput) timeInput.focus();
                        }, 100);
                      }}
                      minDate={moment().toDate()}
                      className="timePicker"
                      placeholderText="Select Date"
                      dateFormat={
                        companyOnboarding?.date_format === "DD/MM/YYYY"
                          ? "dd/MM/yyyy"
                          : "MM/dd/yyyy"
                      }
                      autoComplete="off"
                      popperPlacement="auto"
                      popperProps={{
                        modifiers: [
                          {
                            name: "zIndex",
                            options: {
                              zIndex: 10,
                            },
                          },
                        ],
                      }}
                    />
                    <label
                      className="activeLabel"
                      style={{ fontSize: "12px", height: "10px" }}
                    >
                      Available Date
                    </label>
                    {errors.availableDate && touched.availableDate && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: "12px",
                        }}
                      >
                        {errors.availableDate}
                      </span>
                    )}
                  </div>

                  <div
                    className="controlsForms"
                    style={{
                      width: "103%",
                    }}
                  >
                    <DatePicker
                      name="timePicker"
                      selected={Date.parse(values.availableTime)}
                      onChange={(date) => {
                        setFieldValue("availableTime", date);
                        setTimeout(() => {
                          const checkbox = document.querySelector(
                            'input[name="isEmpty"]'
                          );
                          if (checkbox) checkbox.focus();
                        }, 100);
                      }}
                      showTimeSelect
                      value={values.availableTime}
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="Select time"
                      autoComplete="off"
                      timeFormat="h:mm aa"
                      closeOnTab={true}
                      className={
                        touched.availableTime && errors.availableTime
                          ? "timePickerErr"
                          : "timePicker"
                      }
                    />

                    <label
                      className="activeLabel"
                      style={{
                        fontSize: "12px",
                        height: "10px",
                        color:
                          touched.availableTime &&
                          errors.availableTime &&
                          "#d32f2f",
                      }}
                    >
                      Available Time
                    </label>
                    {touched.availableTime && errors.availableTime && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: "12px",
                          marginTop: "-10px",
                          marginLeft: "14px",
                        }}
                      >
                        {errors.availableTime}
                      </span>
                    )}
                  </div>

                  <Box sx={{ margin: 0 }}>
                    <FormControlLabel
                      label="Truck is empty"
                      name="truckEmptyLabel"
                      labelPlacement="end"
                      sx={{
                        marginRight: 10,
                        padding: 0,
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                        },
                      }}
                      tabIndex={0}
                      control={
                        <Checkbox
                          name="isEmpty"
                          checked={values.isEmpty}
                          onChange={handleChange}
                          sx={{
                            color: "#ccc",
                            "&.Mui-checked": {
                              color: "#4A9876",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              margin: 0,
                            },
                          }}
                        />
                      }
                    />
                  </Box>
                </Box>

                <div className="modal__buttons">
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    style={{ bottom: "10px" }}
                    className="modal__cancel-button"
                    tabIndex={0}
                  >
                    Cancel
                  </button>

                  <MuiButton
                    tabIndex={0}
                    type="submit"
                    className="saveBtn modal__confirm-button"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "20%",
                      width: "auto",
                    }}
                    disabled={loading}
                    endIcon={<AddIcon style={{ color: "#62CA9D" }} />}
                  >
                    {loading ? "Saving..." : "Save"}
                  </MuiButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {showCarrierModal && (
          <CreateCarrierModal
            showOpportunityModal={showCarrierModal}
            setShowOpportunityModal={handleOpenCarrierModal}
            handleCloseModal={handleCloseCarrierModal}
          />
        )}
        {showNewCarrierModal && (
          <CreateCarrierCustomModal
            showOpportunityModal={showNewCarrierModal}
            setShowOpportunityModal={handleOpenNewCarrierModal}
            handleCloseModal={handleCloseNewCarrierModal}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddAvailableTruck;
