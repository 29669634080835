import { Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import AddAvailableTruck from "./Modals/AddAvailableTruck";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import axios from "axios";
import DeleteIconRed from "../../images/DeleteIconRed.svg";
import { Col, Row } from "react-grid-system";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Typography, IconButton, Collapse, Chip } from "@mui/material";
import DeleteTruckModal from "./Modals/DeleteTruckModal";
import moment from "moment";
import { setActiveTabIndex, setTab } from "../../redux";
import Pagination from "react-js-pagination";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "./TruckList.scss";
import DashboardLoader from "../Loaders/DashboardLoader";
import ShipperCard from "./styles/ShipperCard";

const TrucksList = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [showAddTruck, setShowAddTruck] = useState(false);
  const [userCarrierQuery, setUserCarrierQuery] = useState("");
  const [carrierLoading, setCarrierLoading] = useState(false);
  const [carrierData, setCarrierData] = useState([]);
  const [showDeleteModal, setShowDeletModal] = useState(false);
  const [selectedCapacity, setSelectedCapacity] = useState();
  const [sortDirection, setSortDirection] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);

  const [activePageShipper, setActivePageShipper] = useState(1);
  const [totalShipper, setTotalShipper] = useState(0);

  const [expandedRow, setExpandedRow] = useState(null);
  const [capacities, setCapacities] = useState();

  const [shippersLoading, setShippersLoading] = useState(false);
  const [potentialShippers, setPotentialShippers] = useState();

  const [selectedFilters, setFilters] = useState({
    available_from: null,
    available_to: null,
    equipmentTypes: [],
    location: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
  });

  //Redux States
  const allEquipmentTypes = useSelector(
    (state) => state.allEquipmentTypes.equipmentTypes
  );
  const companyOnboarding = useSelector(
    (state) => state.login.companyOnboarding
  );
  const authUser = useSelector((state) => state.login.authUser);
  const isCarrierFieldsModified = useSelector(
    (state) => state.allCarrierFields.isModified
  );

  const dispatch = useDispatch();

  const totalTabs = useSelector((state) => state.tabsState?.tabs?.length);

  const tableHeaders = [
    { key: "carrier_name", title: "Carrier", sort: true },
    { key: "city", title: "Location", sort: true },
    { key: "equipment_types", title: "Equipment", sort: false },
    { key: "status", title: "Status", sort: true },
    { key: "homebase", title: "Home Base", sort: false },
    { key: "available_at", title: "Available", sort: true },
    { key: "actions", title: "Actions", sort: false },
  ];

  const handleExpandRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const formatAddress = (address) => {
    return address?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "").trim();
  };

  const handleCarrierClick = (data, event) => {
    const newTabData = {
      type: "carrier",
      id: data?.carrier?.id,
      name: data?.carrier?.name,
    };

    if (event.metaKey || event.ctrlKey) {
      dispatch(setTab({ ...newTabData, blank: true }));
    } else {
      dispatch(setActiveTabIndex(totalTabs + 1));
      dispatch(setTab(newTabData));
      history.push("/active-tabs");
    }
  };

  const fetchCarriers = () => {
    setCarrierLoading(true);

    let url = `/carriers?_limit=30&_page=1&sort_key=name&sort_dir=asc&search_by=carrier&user_ids=${authUser.id}`;

    axios({
      method: "GET",
      url: url,
    }).then((res) => {
      setCarrierData(
        res.data.carriers.map((item) => ({
          id: item.id,
          name: item.name,
          mc: item.mc_number,
        }))
      );

      setCarrierLoading(false);
    });
  };
  const fetchShippers = (page = 1, city, state, lat, long, id) => {
    setActivePageShipper(page);
    setShippersLoading(true);

    let url = `/carriers/capacities/shippers?_limit=10&_page=${page}&origin_city=${city}&origin_state=${state}&origin_lat=${lat}&origin_long=${long}&carrier_id=${id}`;

    axios({
      method: "GET",
      url: url,
    }).then((res) => {
      setPotentialShippers(res.data.shippers);
      setTotalShipper(res.data.total);
      setShippersLoading(false);
    });
  };

  const fetchCapacities = (pageParam = 1, sortKey) => {
    setLoading(true);

    let url = `/carriers/capacities?_limit=30&_page=${pageParam}`;

    const availableFrom =
      selectedFilters.available_from &&
      moment(selectedFilters.available_from).format("YYYY/MM/DD");

    const availableTo =
      selectedFilters.available_to &&
      moment(selectedFilters.available_to).format("YYYY/MM/DD");

    if (sortKey) {
      const dir = sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }

    if (selectedFilters.location.city) {
      url += `&city=${selectedFilters.location.city}`;
    } else if (selectedFilters.location.state) {
      url += `&state=${selectedFilters.location.state}`;
    } else if (selectedFilters.location.zip) {
      url += `&zip=${selectedFilters.location.zip}`;
    }

    if (
      selectedFilters.equipmentTypes &&
      selectedFilters.equipmentTypes?.length > 0
    ) {
      url += `&equipment_type_id=${selectedFilters.equipmentTypes
        ?.map((type) => type.value)
        .join(",")}`;
    }

    if (selectedFilters.available_from) {
      url += `&available_from=${availableFrom}`;
    }

    if (selectedFilters.available_to) {
      url += `&available_to=${availableTo}`;
    }
    axios({
      method: "GET",
      url: url,
    }).then((res) => {
      setCapacities(res.data.capacities);
      setTotal(res.data.total);
      setLoading(false);
      setPotentialShippers();
    });
  };

  const searchCarriers = debounce(() => {
    setCarrierLoading(true);

    let url = `/carriers/search`;

    if (userCarrierQuery) {
      url += `?query=${encodeURIComponent(userCarrierQuery)}`;
    }

    axios({
      method: "GET",
      url: url,
    }).then((res) => {
      if (userCarrierQuery) {
        setCarrierData((prevReports) => {
          const newData = res.data.carriers.map((item) => ({
            id: item.id,
            name: item.name,
            mc: item.mc_number,
          }));

          // Combine arrays and remove duplicates based on id
          const combinedCarrier = [...prevReports, ...newData];
          const uniqueCarrier = Array.from(
            new Map(combinedCarrier.map((item) => [item.id, item])).values()
          );

          return uniqueCarrier;
        });
      } else {
        setCarrierData(
          res.data.carriers.map((item) => ({
            id: item.id,
            name: item.name,
            mc: item.mc_number,
          }))
        );
      }
      setCarrierLoading(false);
    });
  }, 500);

  useEffect(() => {
    fetchCapacities(1, null);
    fetchCarriers();
  }, []);

  useEffect(() => {
    fetchCapacities(1, null);
  }, [selectedFilters]);

  useEffect(() => {
    if (userCarrierQuery) {
      searchCarriers();
    }
  }, [userCarrierQuery]);

  const getStatusStyles = (status) => {
    switch (status.toLowerCase()) {
      case "empty":
        return {
          bg: "#e8f5e9",
          text: "#2e7d32",
        };

      case "loaded":
        return {
          bg: "#fceecf",
          text: "#cd8003",
        };
      default:
        return {
          bg: "#e0e0e0",
          text: "#616161",
        };
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      outline: "#4A9876",
      boxShadow: "none",
      cursor: "pointer",
      height: "35px",
      borderColor: "lightgray",

      ":active": {
        ...styles[":active"],
        border: "2px solid #4A9876",
        outline: "#4A9876",
      },
      ":hover": {
        ...styles[":hover"],
        border: "2px solid #4A9876",
      },
      ":focus": {
        ...styles[":hover"],
        border: "2px solid #4A9876",
      },
    }),
    indicatorSeparator: (styles) => {
      return {
        display: "none",
      };
    },

    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),

    valueContainer: (styles) => {
      return {
        ...styles,
        height: "37px",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#3aab7b"
          : isFocused
          ? "#C0EAD8"
          : null,
        cursor: isDisabled ? "not-allowed" : "pointer",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#C0EAD8",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "black",
    }),
  };

  return (
    <div className="reports" style={{ paddingBottom: "30px" }}>
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
          paddingBottom: "20px",
        }}
      >
        <button
          className="button-md"
          style={{ float: "right", marginTop: "0px" }}
          type="button"
          onClick={() => setShowAddTruck(true)}
        >
          Add Capacity{" "}
          <Plus
            size={14}
            strokeWidth={3}
            color="#62CA9D"
            style={{
              transform: "translateY(2px)",
              marginLeft: "5px",
            }}
          />
        </button>
        <h2 style={{ marginBottom: "0px" }}>
          Available Capacity{" "}
          <span style={{ color: "#4A9876" }}>
            {(capacities && total) || ""}
          </span>
        </h2>
      </div>
      {/* Filters */}
      <Box
        sx={{
          display: "grid",
          width: "80%",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "20px",
          marginTop: "40px",
          marginBottom: "10px",
          paddingRight: "30px",
        }}
      >
        <div className="controlsForms" style={{ width: "100%" }}>
          <div className="field">
            <GoogleAutocompleteComponent
              className="locationFilter"
              onPlaceSelect={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  location: selectedOptions,
                }))
              }
            />
          </div>

          <label
            className="activeLabel"
            style={{
              fontSize: "12px",
            }}
          >
            Location
          </label>
        </div>
        <div className="controlsForms" style={{ width: "100%" }}>
          <Select
            value={selectedFilters.equipmentTypes}
            closeMenuOnSelect={false}
            isMulti
            styles={colourStyles}
            options={allEquipmentTypes?.map((type) => {
              return { value: type.id, label: type.name };
            })}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            placeholder="-Select-"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOptions) =>
              setFilters((state) => ({
                ...state,
                equipmentTypes: selectedOptions,
              }))
            }
          />
          <label
            className="activeLabel"
            style={{
              fontSize: "12px",
            }}
          >
            Equipment Types
          </label>
        </div>

        <div
          className="controlsForms"
          style={{
            width: "100%",
          }}
        >
          <div className="field">
            <DatePicker
              selected={selectedFilters.available_from}
              name="availableDate"
              value={selectedFilters.available_from}
              onChange={(date) => {
                setFilters((state) => ({
                  ...state,
                  available_from: date,
                }));
              }}
              minDate={moment().toDate()}
              className="datePickerFilter"
              placeholderText="Select Date"
              dateFormat={
                companyOnboarding?.date_format === "DD/MM/YYYY"
                  ? "dd/MM/yyyy"
                  : "MM/dd/yyyy"
              }
              autoComplete="off"
              popperPlacement="auto"
              popperProps={{
                modifiers: [
                  {
                    name: "zIndex",
                    options: {
                      zIndex: 10,
                    },
                  },
                ],
              }}
            />
            <label
              className="activeLabel"
              style={{ fontSize: "12px", height: "10px" }}
            >
              Available From
            </label>
          </div>
        </div>
        <div
          className="controlsForms"
          style={{
            width: "100%",
          }}
        >
          <div className="field">
            <DatePicker
              selected={selectedFilters.available_to}
              name="availableTo"
              value={selectedFilters.available_to}
              onChange={(date) => {
                setFilters((state) => ({
                  ...state,
                  available_to: date,
                }));
              }}
              minDate={moment().toDate()}
              className="datePickerFilter"
              placeholderText="Select Date"
              dateFormat={
                companyOnboarding?.date_format === "DD/MM/YYYY"
                  ? "dd/MM/yyyy"
                  : "MM/dd/yyyy"
              }
              autoComplete="off"
              popperPlacement="auto"
              popperProps={{
                modifiers: [
                  {
                    name: "zIndex",
                    options: {
                      zIndex: 10,
                    },
                  },
                ],
              }}
            />
            <label
              className="activeLabel"
              style={{ fontSize: "12px", height: "10px" }}
            >
              Available To
            </label>
          </div>
        </div>
      </Box>

      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : capacities && capacities.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "500",
          }}
        >
          No records found.
        </div>
      ) : (
        <table
          className="accountList__table"
          style={{ marginTop: "40px", width: "93vw", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index}>
                  <Row>
                    <Col lg={9} xl={9}>
                      {header.title}
                    </Col>
                    {header.sort && (
                      <Col lg={3} xl={3}>
                        <button
                          type="button"
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            outline: "none",
                            color: "#657885",
                          }}
                          onClick={() => {
                            setSortDirection(!sortDirection);
                            fetchCapacities(activePage, header.key);
                          }}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                          <i className="fa fa-long-arrow-down"></i>
                        </button>
                      </Col>
                    )}
                  </Row>
                </th>
              ))}
            </tr>
          </thead>
          {capacities && Array.isArray(capacities) && (
            <tbody style={{ fontSize: "16px" }}>
              {capacities?.map((item, index) => {
                return (
                  <React.Fragment key={item.carrier_id + index}>
                    <tr>
                      <td>
                        <div
                          style={{ cursor: "pointer", color: "#4A9876" }}
                          onClick={(e) => handleCarrierClick(item, e)}
                        >
                          {item.carrier?.name}
                        </div>
                        <div style={{ color: "#586874" }}>
                          MC# {item.carrier?.mc_number}
                        </div>
                      </td>
                      <td>{formatAddress(item.address)}</td>
                      <td>{item.equipment_type_name}</td>
                      <td>
                        <Chip
                          label={item.status}
                          size="small"
                          sx={{
                            backgroundColor: getStatusStyles(item.status).bg,
                            color: getStatusStyles(item.status).text,
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            height: "24px",
                            borderRadius: "16px",
                            fontSize: "13px",
                          }}
                        />
                      </td>
                      <td>{formatAddress(item.carrier?.address)}</td>
                      <td>
                        {moment
                          .utc(item.available_at)
                          .local()
                          .format("MM/DD/YYYY hh:mm A")}
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <IconButton onClick={() => handleExpandRow(index)}>
                            {expandedRow === index ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <span
                                onClick={() => {
                                  fetchShippers(
                                    1,
                                    item.city,
                                    item.state,
                                    item.lat,
                                    item.long,
                                    item.carrier_id
                                  );
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </span>
                            )}
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              setSelectedCapacity(item.id);
                              setShowDeletModal(true);
                            }}
                          >
                            <img
                              src={DeleteIconRed}
                              alt="Delete"
                              width="18px"
                            />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                    {/* Collapsible row */}
                    <tr key={index}>
                      <td
                        colSpan={7}
                        style={{
                          padding: 0,
                        }}
                      >
                        <Collapse
                          in={expandedRow === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          {shippersLoading ? (
                            <Box
                              sx={{
                                backgroundColor: "#e8f5e9",
                                borderWidth: "2px",
                                borderColor: "lightgray",
                                py: 8,
                              }}
                            >
                              <DashboardLoader />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                backgroundColor: "#e8f5e9",
                                borderWidth: "2px",
                                borderColor: "lightgray",
                                py: 2,
                                px: 2,
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                component="div"
                                sx={{ fontWeight: "bold", color: "#2e7d32" }}
                              >
                                Potential Shippers in{" "}
                                {formatAddress(item.address)}
                              </Typography>
                              {potentialShippers &&
                                potentialShippers?.map((shipper, idx) => (
                                  <ShipperCard key={idx} shipper={shipper} />
                                ))}

                              <Pagination
                                activePage={activePageShipper}
                                itemsCountPerPage={10}
                                totalItemsCount={totalShipper}
                                pageRangeDisplayed={5}
                                onChange={(shipperPage) => {
                                  setActivePageShipper(shipperPage);
                                  fetchShippers(
                                    shipperPage,
                                    item.city,
                                    item.state,
                                    item.lat,
                                    item.long,
                                    item.carrier_id
                                  );
                                }}
                                itemClass="page-item"
                                linkClass="page-link"
                                hideDisabled
                              />
                            </Box>
                          )}
                        </Collapse>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
      )}
      {showAddTruck && (
        <AddAvailableTruck
          showModal={showAddTruck}
          handleCloseModal={() => setShowAddTruck(false)}
          allEquipmentTypes={allEquipmentTypes}
          companyOnboarding={companyOnboarding}
          isCarrierLoading={carrierLoading}
          carrierData={carrierData}
          setQuery={setUserCarrierQuery}
          isCarrierFieldsModified={isCarrierFieldsModified}
          fetchCapacities={fetchCapacities}
          colourStyles={colourStyles}
        />
      )}
      {showDeleteModal && (
        <DeleteTruckModal
          showModal={showDeleteModal}
          handleCloseModal={() => setShowDeletModal(false)}
          selectedCapacity={selectedCapacity}
          fetchCapacities={fetchCapacities}
        />
      )}
      <Pagination
        activePage={activePage}
        itemsCountPerPage={30}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          setActivePage(page);
          fetchCapacities(page, null);
        }}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
      />
    </div>
  );
};

export default TrucksList;
