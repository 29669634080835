import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import GmailLogo from "../../images/GmailLogo.png";
import OutlookLogo from "../../images/OutlookLogo.png";
import PostmarkLogo from "../../images/PostmarkLogo.png";

import { BASE_URL } from "../../config";
import axios from "axios";
import { NotificationManager } from "react-notifications";
//import "./IntegrationPage.scss";
import PostMarkAppModal from "../Reports/PostMarkAppModal";
import PostMarkAppVerificationModal from "../Reports/PostMarkAppVerificationModal";
// Redux stuff
import { connect } from "react-redux";
import { authUserSet, companyOnboardingSet } from "../../redux";
import EditSignatureModal from "../SendEmail/EditSignatureModal";
import ReplyPreference from "./ReplyPreference";
import DashboardLoader from "./../Loaders/DashboardLoader";

const EmailSettings = (props) => {
  const [integrated, setIntegrated] = useState(false);
  const [postMarkId, setPostMarkId] = useState(null);
  const [postMarkVerified, setPostMarkVerified] = useState(false);
  const [showPostMarkModal, setShowPostMarkModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [mkimHost, setMkimHost] = useState(null);
  const [mkimTextValue, setMkimTextValue] = useState(null);

  const [showRingCentralWidget, setShowRingCentralWidget] = useState(false);
  const [enableInboundOutlook, setEnableInboundOutlook] = useState(false);
  const [userData, setUserData] = useState({});
  const [postmarkData, setPostmarkData] = useState({});

  const [emailSettings, setEmailSettings] = useState();
  const [emailSettingsLoading, setEmailSettingsLoading] = useState(false);
  const [signature, setSignature] = useState();
  const [showSignature, setShowSignature] = useState(false);
  const [showReplyPreference, setShowReplyPreference] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [companySettings, setCompanySettings] = useState({});

  const [integrations] = useState([
    {
      id: "gmail",
      name: "Gmail",
      icon: GmailLogo,
      status: "connected",
      description:
        "Send outbound emails (not inbound) from Salesdash to contacts.",
      category: "email",
      iconWidth: "40px",
      iconHeight: "35px",
    },
    {
      id: "outlook",
      name: "Outlook",
      icon: OutlookLogo,
      status: "available",
      actionLabel: "Outlook Integration",
      description:
        "Send outbound emails (not inbound) from Salesdash to contacts.",
      category: "email",
      iconWidth: "40px",
      iconHeight: "40px",
    },
  ]);
  const [postMarkIntegration] = useState([
    {
      id: "sd-email",
      name: "Postmark (Bulk Email)",
      icon: PostmarkLogo,
      status: "connected",
      description:
        "Send bulk emails and email sequences via Postmark. Emails are sent and received only in Salesdash, not your Gmail or Outlook. This is a paid add-on in your Billing page.",
      category: "email",
      iconWidth: "40px",
      iconHeight: "40px",
      details: {
        hostname: "pm-bounces",
        type: "CNAME",
        value: "pm.mtasv.net",
      },
    },
  ]);

  const url_string = window.location.href;
  const url = new URL(url_string);
  const siteName = url.hostname.split(".");
  const errorType = url.searchParams.get("error");
  const location = useLocation();
  const history = useHistory();
  const fetchMailProvider = () => {
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) => {
      setIntegrated(res.data.user.mail_provider);
      setPostMarkId(res.data.user.postmark_signature_id);
      setPostMarkVerified(res.data.user.postmark_signature_verified);
      setMkimHost(res.data.user.dkim_host);
      setMkimTextValue(res.data.user.dkim_text_value);
      setPostmarkData(res.data.postmark_signature_details);
    });
  };
  const getUserDetails = () => {
    axios({
      method: "GET",
      url: `/users`,
    }).then((res) => {
      setUserData(res.data.user);
      setShowRingCentralWidget(res.data.user.is_ringcentral_enabled);
      setEnableInboundOutlook(res.data.user.is_inbound_om_enabled);
    });
  };
  const getCompanySettings = () => {
    axios({
      method: "GET",
      url: `/company/settings`,
    }).then((res) => {
      setCompanySettings(res.data.settings);
      props.companyOnboardingSet({
        ...props.companyOnboarding,
        tms_integrated: res.data.settings.tms_integrated,
      });
      localStorage.setItem(
        "companyOnboarding",
        JSON.stringify({
          ...props.companyOnboarding,
          tms_integrated: res.data.settings.tms_integrated,
        })
      );
    });
  };

  const getEmailSettings = () => {
    setEmailSettingsLoading(true);

    let url = `/emails/settings`;

    axios({
      method: "GET",
      url,
    }).then((res) => {
      setEmailSettings(res.data);
      setEmailSettingsLoading(false);
    });
  };

  const getSignature = () => {
    setSignatureLoading(true);
    axios({
      method: "GET",
      url: `/users/email-signature`,
    }).then((res) => {
      if (res.data.email_signature !== null) {
        setSignature(res.data.email_signature);
        setSignatureLoading(false);
      }
    });
  };

  const handleOpenSignatureModal = () => {
    setShowSignature(true);
  };

  const handleCloseSignatureModal = () => {
    setShowSignature(false);
  };
  const handleOpenReplyeModal = () => {
    setShowReplyPreference(true);
  };

  const handleCloseReplyeModal = () => {
    setShowReplyPreference(false);
  };
  useEffect(() => {
    fetchMailProvider();
    getUserDetails();
    getCompanySettings();
    getEmailSettings();
    getSignature();
  }, []);
  useEffect(() => {
    if (errorType === "insufficient_permissions") {
      NotificationManager.error(
        "We need all the permissions to integrate google account"
      );
    }
    if (errorType === "access_denied") {
      NotificationManager.error(
        "There was a problem integrating to your outlook account. Please try again."
      );
    }
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("error")) {
      queryParams.delete("error");
      history.replace({
        search: queryParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType]);
  const disconnectMailProvider = () => {
    axios({
      method: "POST",
      url: `/users/disconnect-mail-provider`,
      data: {},
    })
      .then((res) => {
        fetchMailProvider();
        NotificationManager.success("Mail Provider disconnected");
      })
      .catch((err) =>
        NotificationManager.error("Error disconnecting mail provider")
      );
  };
  const checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      setPostMarkId(res.data.postmark_signature_id);
      setPostMarkVerified(res.data.postmark_signature_verified);
    });
  };
  const checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      verifySignature(res.data.postmark_signature_verified);
      setPostMarkId(res.data.postmark_signature_id);
      setPostMarkVerified(res.data.postmark_signature_verified);
    });
  };
  const verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          setShowVerificationModal(false);
          checkUserProfile();
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  const changeEnableOutlook = (is_inbound_om_enabled) => {
    axios({
      method: "PUT",
      url: `/users/config`,
      data: {
        is_inbound_om_enabled,
      },
    });
  };

  const integrationDecision = () => {
    if (postMarkId === null) {
      setShowPostMarkModal(true);
    } else if (!postMarkVerified) {
      setShowVerificationModal(true);
    }
  };

  const copyKey = (apiKey) => {
    // Create temporary textarea
    const textarea = document.createElement("textarea");
    textarea.value = apiKey;
    textarea.style.position = "fixed"; // Ensure textarea is not visible
    textarea.style.opacity = "0";
    document.body.appendChild(textarea);

    // Select the text
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    // Execute copy command
    try {
      document.execCommand("copy");
      NotificationManager.success("Key copied!");
    } catch (err) {
      NotificationManager.error("Failed to copy key!");
      console.error("Failed to copy:", err);
    }

    // Cleanup
    document.body.removeChild(textarea);
  };

  const ConnectButton = (name) => (
    <button className="status-button available">
      <span className="icon plus">+</span>
      Connect {name}
    </button>
  );

  const StatusButton = ({ status, name, id }) => {
    switch (id) {
      case "gmail":
        return integrated === "google" ? (
          <button
            className="status-button connected"
            onClick={() => disconnectMailProvider()}
          >
            <span className="icon check">✓</span>
            Connected
          </button>
        ) : (
          <a
            href={`${BASE_URL}/auth/google-email/login?site_name=${siteName[0]}&user_id=${props.authUser.id}`}
          >
            <button className="status-button available">
              <span className="icon plus">+</span>
              Connect {name}
            </button>
          </a>
        );
      case "outlook":
        return integrated === "outlook" ? (
          <button
            className="status-button connected"
            onClick={() => disconnectMailProvider()}
          >
            <span className="icon check">✓</span>
            Connected
          </button>
        ) : (
          <a
            href={`${BASE_URL}/auth/outlook-email/login?site_name=${siteName[0]}&user_id=${props.authUser.id}`}
          >
            <button className="status-button available">
              <span className="icon plus">+</span>
              Connect {name}
            </button>
          </a>
        );
      case "sd-email":
        return postMarkId !== null && postMarkVerified ? (
          <button className="status-button connected">
            <span className="icon check">✓</span>
            Connected
          </button>
        ) : (
          <button
            onClick={() => integrationDecision(true)}
            className="status-button available"
          >
            <span className="icon plus">+</span>
            Connect
          </button>
        );

      default:
        return null;
    }
  };

  const ActionButton = ({ type, label, linkType, link, downloadLink }) => {
    switch (type) {
      case "redirect":
        return linkType === "internal" ? (
          <Link to={link}>
            <button className="action-button redirect">
              <span className="icon external">↗</span>
              {label}
            </button>
          </Link>
        ) : (
          <a href={link} target="_blank" rel="noreferrer">
            <button className="action-button redirect">
              <span className="icon external">↗</span>
              {label}
            </button>
          </a>
        );
      case "download":
        return (
          <a href={downloadLink} target="_blank" rel="noreferrer">
            <button className="action-button download">{label}</button>
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <div className="integrations-page">
      <PostMarkAppModal
        showOpportunityModal={showPostMarkModal}
        setShowOpportunityModal={() => setShowPostMarkModal(true)}
        handleCloseModal={() => setShowPostMarkModal(false)}
        handleVerificationOpenModal={() => setShowVerificationModal(true)}
        checkUserProfile={checkUserProfile}
      />
      <PostMarkAppVerificationModal
        showOpportunityModal={showVerificationModal}
        setShowOpportunityModal={() => setShowVerificationModal(true)}
        handleCloseModal={() => setShowVerificationModal(false)}
        checkUserProfile={checkUserProfile}
        checkVerification={checkVerification}
      />

      <div
        className="integrations-page-header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="header-content">
          <h1>Email Settings</h1>
          <p>Manage your connected services and other settings</p>
        </div>
      </div>

      <div
        className="integrations-page-header"
        style={{ margin: "0", padding: "0" }}
      >
        <div className="header-content" style={{ width: "40%" }}>
          <h2>1. Integrate With Gmail or Outlook</h2>
          <p>
            Connect with Gmail and Outlook to send emails to your prospects and
            customers from your CRM. Emails automatically log in the activity
            timeline. Responses will be logged only for emails that you send
            from the CRM.
          </p>
        </div>
      </div>
      <div className="integrations-grid">
        {integrations.map((integration) => (
          <div key={integration.id} className="integration-card">
            <div className="integration-content">
              <div className="integration-main">
                <div className="integration-icon">
                  <img
                    src={integration.icon}
                    alt={integration.name}
                    style={{
                      width: integration.iconWidth,
                      height: integration.iconHeight,
                    }}
                  />
                </div>
                <div className="integration-info">
                  <div className="integration-header">
                    <div>
                      <h3>{integration.name}</h3>
                    </div>
                    <StatusButton
                      status={integration.status}
                      name={integration.name}
                      id={integration.id}
                    />
                  </div>
                  <p className="integration-description">
                    {integration.description}
                  </p>
                </div>
              </div>

              {integration.actionType && (
                <div className="action-section">
                  <ActionButton
                    type={integration.actionType}
                    label={integration.actionLabel}
                    linkType={integration.linkType}
                    link={integration.link}
                    downloadLink={integration.downloadLink}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div style={{ width: "49.5%", marginTop: "40px" }}>
        <div
          className="integrations-page-header"
          style={{ margin: "0", padding: "0" }}
        >
          <div className="header-content" style={{ width: "80%" }}>
            <h2>2. Set Your Email Signature</h2>
            <p>
              Create your email signature for your emails that you send from
              Salesdash. For images, please do NOT copy and paste. Upload images
              and logos into your signature for the best appearance.
            </p>
          </div>
        </div>
        <div className="integration-card">
          <div className="integration-content">
            {signatureLoading ? (
              <DashboardLoader />
            ) : signature ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: signature }} />
                <div>
                  <button
                    className="button-md"
                    style={{ padding: "6px 12px", borderRadius: "6px" }}
                    onClick={handleOpenSignatureModal}
                  >
                    Edit
                  </button>
                </div>
              </div>
            ) : (
              "No Signature Found"
            )}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "40px" }}>
        <div
          className="integrations-page-header"
          style={{ margin: "0", padding: "0" }}
        >
          <div className="header-content" style={{ width: "40%" }}>
            <h2>3. Bulk Email Settings (Paid Add-on)</h2>{" "}
            <p>
              Postmark is a paid add-on for bulk emails and email sequences. You
              can purchase email credits for these emails in the Billing page of
              your CRM. Please note: these emails are sent through Postmark’s
              email servers, NOT your Gmail or Outlook. All sent emails and the
              replies will only be in Salesdash, not in your email inbox.
            </p>
          </div>
        </div>
        <div className="integrations-grid">
          {postMarkIntegration.map((integration) => (
            <div key={integration.id} className="integration-card">
              <div className="integration-content">
                <div className="integration-main">
                  <div className="integration-icon">
                    <img
                      src={integration.icon}
                      alt={integration.name}
                      style={{
                        width: integration.iconWidth,
                        height: integration.iconHeight,
                      }}
                    />
                  </div>
                  <div className="integration-info">
                    <div className="integration-header">
                      <div>
                        <h3>{integration.name}</h3>
                      </div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <StatusButton
                          status={integration.status}
                          name={integration.name}
                          id={integration.id}
                        />

                        <button
                          className="button-md"
                          onClick={handleOpenReplyeModal}
                          style={{ padding: "6px 12px", borderRadius: "6px" }}
                        >
                          Reply Preference
                        </button>
                      </div>
                    </div>
                    <p className="integration-description">
                      {integration.description} <br />
                      <br />
                      Authenticate emails sent for your domains (DKIM) and route
                      bounce emails to your email service provider (CNAME for
                      bounce tracking) in the Integrations page in Salesdash.
                    </p>

                    {postMarkId !== null &&
                      postMarkVerified &&
                      integration.id === "sd-email" && (
                        <>
                          <div className="api-key-section">
                            <span className="api-key-label">DKIM Host:</span>
                            <code className="api-key-value">{mkimHost}</code>
                            <button
                              className="copy-button"
                              onClick={() => copyKey(mkimHost)}
                            >
                              Copy
                            </button>
                          </div>
                          <div className="api-key-section">
                            <span className="api-key-label">
                              DKIM Text Value:
                            </span>
                            <code className="api-key-value">
                              {mkimTextValue}
                            </code>
                            <button
                              className="copy-button"
                              onClick={() => copyKey(mkimTextValue)}
                            >
                              Copy
                            </button>
                          </div>
                          <div className="details-section">
                            <div className="detail-row">
                              <span className="detail-label">Hostname:</span>
                              <span className="detail-value">
                                {integration.details.hostname}
                              </span>
                            </div>
                            <div className="detail-row">
                              <span className="detail-label">Type:</span>
                              <span className="detail-value">
                                {integration.details.type}
                              </span>
                            </div>
                            <div className="detail-row">
                              <span className="detail-label">Value:</span>
                              <span className="detail-value">
                                {integration.details.value}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>

                {integration.actionType && (
                  <div className="action-section">
                    <ActionButton
                      type={integration.actionType}
                      label={integration.actionLabel}
                      linkType={integration.linkType}
                      link={integration.link}
                      downloadLink={integration.downloadLink}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {showSignature && (
        <EditSignatureModal
          showOpportunityModal={showSignature}
          setShowOpportunityModal={handleOpenSignatureModal}
          handleCloseModal={handleCloseSignatureModal}
          isEmailSettings={true}
          getSignature={getSignature}
        />
      )}
      {showReplyPreference && (
        <ReplyPreference
          showModal={showReplyPreference}
          setShowOpportunityModal={handleOpenReplyeModal}
          handleCloseModal={handleCloseReplyeModal}
          replyToAddress={emailSettings?.verified_reply_to_addresses}
          signature={signature}
        />
      )}
    </div>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(EmailSettings);
