import React from "react";
import "jodit";
import "../../sass/Jodit.scss";
import JoditEditor from "jodit-react";
import { BASE_URL } from "../../config";

export default class JoditDemo extends React.Component {
  state = {
    allowUpload: true,
    uploadedImages: null,
  };

  updateContent = (value) => {
    this.setState({ content: value });
  };

  jodit = null;

  setRef = (joditInstance) => {
    if (joditInstance) {
      this.jodit = joditInstance;
    }
  };

  beforePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    let hasImage = false;

    if (clipboardData && clipboardData.items) {
      for (let i = 0; i < clipboardData.items.length; i++) {
        const item = clipboardData.items[i];
        if (item.type.indexOf("image") !== -1) {
          hasImage = true;
          event.preventDefault();
        }
      }
    }

    if (hasImage) {
      this.setState({ allowUpload: false }, () => {
        setTimeout(() => {
          this.setState({ allowUpload: true }); // Reset after blocking paste
        }, 500);
      });
    }

    return true;
  };

  config = {
    height: "inherit",
    maxHeight: "inherit",
    minHeight: "inherit",
    width: "100%",
    tabIndex: 1,
    spellcheck: true,
    enableDragAndDropFileToEditor: true,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: true,
    toolbarButtonSize: "small",
    addNewLine: false,
    allowResizeY: false,
    buttons:
      this.props.isTemplate === true
        ? [
            "bold",
            "italic",
            "underline",
            "|",
            "source",
            "|",
            "ul",
            "ol",
            "align",
            "|",
            "fontsize",
            "brush",
            "|",
            "image",
            "link",
          ]
        : this.props.windowWidth > 600
        ? [
            "bold",
            "italic",
            "underline",
            "|",
            "source",
            "|",
            "ul",
            "ol",
            "|",
            "fontsize",
            "brush",
            "align",
            "|",
            "image",
            "insertFile",
            "link",
            "|",
            "insertSignature",
          ]
        : [
            "bold",
            "italic",
            "underline",
            "|",
            "source",
            "|",
            "ul",
            "ol",
            "align",
            "|",
            "image",
            "link",
          ],
    controls: {
      insertFile: {
        name: "insertFile",
        icon: "file",
        tooltip: "Insert File",
        exec: () => this.props.triggerInputFile(),
      },
      insertSignature: {
        name: "insertSignature",
        icon: "pencil",
        tooltip: "Insert Signature",
        exec: () => this.props.handleOpenSignatureModal(),
      },
    },
    link: {
      modeClassName: false,
      processVideoLink: false,
    },
    uploader: {
      url: `${BASE_URL}/attachments`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      prepareData: (formData) => {
        if (!this.state.allowUpload) {
          return false; // Prevent image upload
        }

        const { accountId, contactId, opportunityId, carrierId } = this.props;
        let file = formData.getAll("files[0]")[0];
        formData.append("attachment", file);
        accountId && formData.append("account_id", accountId);
        carrierId && formData.append("carrier_id", carrierId);
        contactId && formData.append("contact_id", contactId);
        opportunityId && formData.append("opportunity_id", opportunityId);
        formData.delete("path");
        formData.delete("source");
        formData.delete("files[0]");
        return formData;
      },
      imagesExtensions: ["jpg", "png", "jpeg", "gif"],
      process: function (resp) {
        return resp;
        // files: resp.files,
        // path: resp.path,
        // baseurl: resp.baseurl,
        // error: resp.error,
        // message: resp.message,
        // };
      },

      defaultHandlerSuccess: function (data) {
        if (this.jodit && data?.s3_url) {
          this.jodit.s.insertImage(data.s3_url);
        } else if (data?.s3_url) {
          this.selection.insertImage(data.s3_url);
        } else {
          console.error("Editor instance not found or invalid image URL");
        }
      },
      defaultHandlerError: (resp) => {
        console.log("jodit def error", resp);
      },
      error: (e) => {
        console.log("jodit main error", e);
        this.props.setUploaderErrorMessage &&
          this.props.setUploaderErrorMessage();
      },
      isSuccess: function (e) {
        if (!e) {
          return false;
        }
        console.log("Jodit isSuccess", e);
        return true;
      },
    },
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
    toolbarSticky: true,
    enter: "BR",
    placeholder: "Type your message...",
    zIndex: 2147483640,
    events: {
      beforePaste: this.beforePaste,
    },
  };
  render() {
    return (
      <JoditEditor
        innerRef={this.setRef}
        //editorRef={this.setRef}
        value={this.props.content}
        config={this.config}
        onChange={(content) => {
          this.props.updateContent(content);
          this.props.inputFocusHandler &&
            this.props.inputFocusHandler("content");
        }}
        onBlur={() =>
          this.props.inputFocusHandler &&
          this.props.inputFocusHandler("content")
        }
      />
    );
  }
}
